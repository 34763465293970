/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  useTheme,
  Text,
  Flex,
} from '@chakra-ui/react';
import moment from 'moment';
import {
  useGetUserQuery,
  useUpdateUserMutation,
} from '../../services/apis/usersAPI';
import { useCreateOnboardingPinsMutation } from '../../services/apis/pinsAPI';

const GuidelinesModal = ({
  custom_text = 'User Guidelines',
  custom_btn_style = {},
  guidelines_accepted,
  btn_type = 'btn',
  open_modal,
  buttonClass,
}) => {
  const theme = useTheme();
  const { data: userData } = useGetUserQuery();
  const user_id = userData?.data?._id;
  const { action, action_date, version } = userData?.data?.community_guidelines?.[0] || {};
  const latest_guidelines_status = userData?.data?.latest_guidelines_status;
  const { isOpen, onOpen, onClose } = useDisclosure({
    defaultIsOpen: latest_guidelines_status === 'pending' || open_modal,
  });
  const [updateUser] = useUpdateUserMutation();
  const [createPins] = useCreateOnboardingPinsMutation({});
  const formattedActionDate = moment(action_date).format('MMM Do, YYYY');

  const guidelines_custom_message = action === 'accepted'
    ? `You agreed to The Exchange Community User Guidelines v${version} on 
  ${formattedActionDate}`
    : action === 'declined'
      ? `You declined The Exchange community User v${version} on 
  ${formattedActionDate}`
      : '';

  const handleOnClick = (e) => {
    e.stopPropagation();
    onOpen();
  };
  const subLinkStyle = {
    minWidth: 'max-content',
    ...theme.styles?.global?.body2,
    color: '#fff',
    cursor: 'pointer',
    fontWeight: 500,
    ...custom_btn_style,
  };

  const closeModal = () => {
    onClose();
  };

  const handleGuidelinesStatus = async (action_taken) => {
    const paramsObj = { latest_guidelines_accepted: action_taken };
    const isGuidelinesPrevAccepted = userData?.data?.community_guidelines?.some((item) => item.action === 'accepted');

    if (user_id) {
      try {
        await updateUser({ user_id, paramsObj }).unwrap();
        if (!isGuidelinesPrevAccepted && action_taken) {
          await createPins().unwrap();
        }
        window.location.reload();
      } catch (error) {
        console.log('Error', error);
      }
    }
  };

  const buttonAttributes = buttonClass ? { className: buttonClass } : {};

  const handleKeyDown = (event) => {
    if (event.keyCode === 13 || event.keyCode === 32) {
      event.preventDefault();
      handleOnClick(event);
    }
  };

  return (
    <>
      {btn_type === 'text' ? (
        <Text
          tabIndex="0"
          aria-label="Open guidelines modal"
          onClick={handleOnClick}
          onKeyDown={handleKeyDown}
          as="p"
          style={subLinkStyle}
          {...buttonAttributes}
        >
          {custom_text}
        </Text>
      ) : (
        <Button
          colorScheme="blue"
          bg="blue.500"
          onClick={handleOnClick}
          {...buttonAttributes}
        >
          {custom_text}
        </Button>
      )}

      <Modal
        isOpen={isOpen}
        onClose={closeModal}
        size="3xl"
        isCentered
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent
          borderTop="8px solid"
          borderColor="blue.600"
          style={{ ...theme.styles.components.modalStyle }}
        >
          <ModalBody>
            <Flex flexDirection="column" gap="0.5rem" mb={2}>
              <h1>The Exchange Community User Guidelines&nbsp;</h1>
              <h4>March 2024&nbsp; v1.1 </h4>
              <p style={{ fontStyle: 'italic' }}>
                Before accessing the rest of the community, please take a moment
                to review and agree to our guidelines for interacting with your
                fellow community members
              </p>
            </Flex>
            <Flex
              flexDirection="column"
              gap="1rem"
              className="guidelines"
              maxHeight="550px"
              overflowY="scroll"
              border="1px solid #CED8DD"
              p={2}
            >
              <p>
                Welcome to The Exchange community, a dedicated space for educators to ask questions and share information and best practices. We hope this community provides a supportive environment where educators seeking to learn and grow can find answers and help from those who have encountered similar challenges. Our mission is to share validated and timely education data to empower all educators in the PK-12 space in Texas, and we are committed to hosting a space where our actions align with that vision and purpose. We believe having data work for you can improve student success.
              </p>
              <p>
                These guidelines outline how to engage with, and what you should expect from, other users in the community.
              </p>
              <p>
                We begin with the expectation that The Exchange is a professional community where all members deserve respect and kindness. This is a welcoming and engaging community filled with like-minded people hoping to create better outcomes in education.
              </p>
              <h5>We promote the following:</h5>
              <ul>
                <li>
                  Follow your district or region’s policies: be sure you align with your organization’s
                  expectations and best practices in public forums
                </li>
                <li>
                  Think before you post: be respectful and mindful of your voice and tone
                </li>
                <li>
                  Be helpful: offer assistance or respond with valuable information on the subject matter
                </li>
                <li>
                  Be open: there are always new thoughts and ideas; be willing to hear them out and
                  be positive about outcomes
                </li>
                <li>
                  Stay on topic: let’s not turn the threads into personal feelings or politics
                </li>
                <li>
                  Keep replies brief: focus on answering questions and on the comment itself
                </li>
                <li>
                  Give credit: when sharing resources, please designate where the information came
                  from; cite your sources
                </li>
              </ul>
              <p>
                The Exchange team will review infractions and determine what actions, if any, need to be taken in response to a post that does not align with the guidelines. In the event a user wishes to appeal a decision, they can do so, and the request will be taken back to The Exchange team for further consideration.

              </p>

              <p>
                How to report violations:
                <br />
                We’re Here to Help
              </p>
              <p>
                The Exchange team is here to ensure we all have a safe and valuable space for sharing ideas and best practices related to educating  Texas students. If you see a post that violates these guidelines, you can alert our team by clicking “Report” under the 3-dot menu in the top-right corner of any post. Once you open the reporting feature, clearly state what you believe the poster has done to violate our user guidelines. The team will receive a notification of the reported item for review and follow-up on each complaint as warranted. Note that content posted to the wrong channel can be flagged. In this instance, moderators will consider moving it to the appropriate location.

              </p>
              <p>
                If a moderator has taken action with regard to one of your posts, you will receive a notification on an infraction page that includes the flagged information. This page also includes the community guidelines and will indicate why your post was flagged. There will be a list of next steps, including an opportunity to appeal the decision. You are invited to share why you believe the moderation should be reversed. If you disagree with a moderator’s decision that affects you, you can submit an appeal via the appeal button on your infraction page.

              </p>
            </Flex>
          </ModalBody>

          <ModalFooter
            flexDir="column"
            justifyContent="flex-start"
            width="100%"
          >
            {action === 'declined' && (
              <Text
                sx={{
                  fontSize: '18px',
                  fontWeight: '600',
                  marginBottom: '16px',
                }}
              >
                {guidelines_custom_message}
              </Text>
            )}
            {guidelines_accepted ? (
              <Text
                sx={{
                  fontSize: '18px',
                  fontWeight: '600',
                  marginBottom: '16px',
                }}
              >
                {guidelines_custom_message}
              </Text>
            ) : (
              <Button
                onClick={() => handleGuidelinesStatus(true)}
                colorScheme="blue"
                bg="blue.500"
                style={{ ...theme.styles.global.button, fontWeight: '600' }}
              >
                I Agree to Follow The Exchange Community User Guidelines
              </Button>
            )}

            {latest_guidelines_status === 'pending' ? (
              <Button
                onClick={() => handleGuidelinesStatus(false)}
                variant="outline"
                aria-label="Close guidelines modal without agreeing"
                sx={{ color: 'black', borderColor: 'gray' }}
                size="sm"
                mt={4}
                _hover={{ opacity: 0.9 }}
              >
                Close without agreeing. You will not be able to interact with
                other members of the Community.
              </Button>
            ) : (
              <Button
                role="button"
                variant="outline"
                onClick={onClose}
                aria-label="Close guidelines modal"
                sx={{ color: 'black', borderColor: 'gray' }}
                size="sm"
                mt={4}
              >
                Close
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default GuidelinesModal;
