import React, { useEffect, useState } from 'react';
import {
  VStack, useTheme, Box,
} from '@chakra-ui/react';
import SectionHeader from '../components/Dashboard/SectionHeader';
import SectionFooter from '../components/NavMenu/SectionFooter';
import { useFetchAllUpdatesQuery } from '../services/apis/updatesAPI';
import StatusAlert from '../components/ElementaryComponents/StatusAlert';
import UpdateCardDashboard from '../components/Cards/UpdateCardDashboard';

const AllUpdates = ({
  showNumberSelect = true,
  orderFilter = true,
}) => {
  const theme = useTheme();
  const [currentPageAllUpdates, setCurrentPageAllUpdates] = useState(1);
  const [selectedShowNumber, setSelectedShowNumber] = useState(5);

  useEffect(() => {
    setCurrentPageAllUpdates(1);
  }, [selectedShowNumber]);

  const updatesQueryObj = {
    page_number: currentPageAllUpdates,
    page_size: selectedShowNumber,
    pin: true,
  };
  const {
    data: allUpdatesData,
    isFetching: isAllUpdatesFetching,
    isError: isAllUpdatesError,
    error: allUpdatesError,
    refetch: refetchUpdates,
  } = useFetchAllUpdatesQuery(updatesQueryObj);

  useEffect(() => {
    refetchUpdates();
  }, [refetchUpdates]);

  if (isAllUpdatesFetching) return <StatusAlert status="loading" alert_title="Fetching All Updates!" />;
  if (isAllUpdatesError) return <StatusAlert status="error" error={allUpdatesError} />;

  return (
    <Box>
      <SectionHeader
        sectionTitle="Updates"
        titleElement="h1"
        titleSize="xl"
      />

      <VStack
        style={{
          ...theme.styles.global.container, minHeight: '4rem', padding: '1rem', alignSelf: 'flex-end',
        }}
      >
        {allUpdatesData?.data?.map((updatesObj, index) => {
          const { _id } = updatesObj;
          return (
            <UpdateCardDashboard
              updatesObj={updatesObj}
              key={_id}
              lastIndex={index === (allUpdatesData.meta.total_updates - 1)}
            />
          );
        })}
        <SectionFooter
          showNumberSelect={showNumberSelect}
          orderFilter={orderFilter}
          selectedShowNumber={selectedShowNumber}
          setSelectedShowNumber={setSelectedShowNumber}
          currentPage={currentPageAllUpdates}
          setCurrentPage={setCurrentPageAllUpdates}
          totalCount={allUpdatesData?.meta?.total_pages}
        />
      </VStack>
    </Box>
  );
};

export default AllUpdates;
