/* eslint-disable no-restricted-globals */
import React, { useEffect, useState } from 'react';
import {
  Box, useTheme, VStack, Heading, HStack, Flex, Input, Select, Button, Spacer, Text, Checkbox,
} from '@chakra-ui/react';
import { useSearchByTextQuery, useSearchByTagsQuery } from '../services/apis/searchAPI';
import SectionFooter from '../components/NavMenu/SectionFooter';
import StatusAlert from '../components/ElementaryComponents/StatusAlert';
import DataUnavailable from '../components/ElementaryComponents/DataUnavailable';
import SearchResultCard from '../components/Cards/SearchResultCard';

const Search = ({
  showNumberSelect = true,
  options = false,
}) => {
  const theme = useTheme();
  const [currentPageSearchResults, setCurrentPageSearchResults] = useState(1);
  const [selectedShowNumber, setSelectedShowNumber] = useState(5);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchSections, setSearchSections] = useState('all');
  const [timeScope, setTimeScope] = useState(14);
  const [tagsOnly, setTagsOnly] = useState(false);
  const [searchQueryParams, setSearchQueryParams] = useState(null);
  const isSearchDisabled = searchTerm.trim().length === 0;
  // eslint-disable-next-line no-unused-vars
  const [initialFetchhDone, setInitialFetchDone] = useState(false);
  const [showTagsResult, setShowTagsResult] = useState(false);
  const [initialPaginatonDone, setInitialPaginatonDone] = useState(false);

  const {
    data: searchResultByText,
    isFetching: isTextSearchFetching,
    isError: isTextSearchError,
    error: textSearchError,
    refetch: refetchTextResult,
  } = useSearchByTextQuery(searchQueryParams, { skip: tagsOnly || searchQueryParams === null });

  const {
    data: searchResultByTags,
    isFetching: isTagsSearchFetching,
    isError: isTagsSearchError,
    error: tagsSearchError,
    refetch: refetchTagResult,
  } = useSearchByTagsQuery(searchQueryParams, { skip: !tagsOnly || searchQueryParams === null });

  const handleSearchClick = () => {
    const paramsObj = {
      page_number: currentPageSearchResults,
      page_size: selectedShowNumber,
    };

    if (tagsOnly) {
      paramsObj.tag = searchTerm;
      setShowTagsResult(true);
    } else {
      paramsObj.query = searchTerm;
      setShowTagsResult(false);
    }

    if (searchSections !== 'all') {
      paramsObj.content_type = searchSections;
    } else {
      delete paramsObj.content_type;
    }

    if (!isNaN(Number(timeScope))) {
      paramsObj.last_created = +timeScope;
    } else {
      delete paramsObj.last_created;
    }
    setSearchQueryParams(paramsObj);

    if (initialFetchhDone) {
      if (!tagsOnly) {
        refetchTextResult(paramsObj);
      } else {
        refetchTagResult(paramsObj);
      }
      setSearchQueryParams(null);
    }
  };

  useEffect(() => {
    if (currentPageSearchResults > 1 || initialPaginatonDone) {
      setInitialPaginatonDone(true);
      handleSearchClick();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPageSearchResults]);

  useEffect(() => {
    if (searchTerm.trim()) {
      handleSearchClick();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedShowNumber]);

  const renderSearchResults = (isTagSearch) => {
    const data = isTagSearch ? searchResultByTags : searchResultByText;
    const isFetching = isTagSearch ? isTagsSearchFetching : isTextSearchFetching;
    const isError = isTagSearch ? isTagsSearchError : isTextSearchError;
    const error = isTagSearch ? tagsSearchError : textSearchError;

    if (isFetching) return <StatusAlert status="loading" alert_title="Searching..." />;
    if (isError) return <StatusAlert status="error" error={error} />;
    if (data?.data.length === 0) return <DataUnavailable display_message={['No results were found for this search.']} />;

    if (data?.data.length > 0) {
      return (
        <VStack
          style={{
            ...theme.styles.global.container, minHeight: '4rem', padding: '1rem', alignSelf: 'flex-end',
          }}
        >
          {data?.data.map((result) => {
            return (
              <Box key={Math.random()} style={{ width: '100%' }}>
                <SearchResultCard resultObj={result} />
              </Box>
            );
          })}
          <SectionFooter
            showNumberSelect={showNumberSelect}
            selectedShowNumber={selectedShowNumber}
            setSelectedShowNumber={setSelectedShowNumber}
            currentPage={currentPageSearchResults}
            setCurrentPage={setCurrentPageSearchResults}
            totalCount={data?.meta?.total_pages}
            options={options}
          />
        </VStack>
      );
    }
    return <DataUnavailable display_message={['Start your search using the query field and filters above.']} />;
  };

  return (
    <Box>
      <Heading
        as="h1"
        style={{
          ...theme.styles.global.h1,
          color: `${theme.colors.blue[800]}`,
        }}
      >
        Search
      </Heading>
      <Flex
        justifyContent="space-between"
        alignItems="center"
        py={4}
      >
        <HStack width="full">
          <Text minW="140px" style={{ ...theme.styles.global.body }}>
            Search Term
          </Text>
          <Input
            borderRadius="4px"
            width="40%"
            value={searchTerm}
            sx={{ padding: '0 12px' }}
            placeholder="Enter your search term(s) here"
            isRequired
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <Checkbox
            width="14%"
            minWidth="110px"
            isChecked={tagsOnly}
            sx={{ justifyContent: 'center', fontWeight: '400', fontSize: '1rem' }}
            onChange={(e) => {
              setSearchQueryParams(null);
              setSearchSections('all');
              setTagsOnly(e.target.checked);
            }}
          >
            Tags Only
          </Checkbox>
          <Select
            width="22%"
            aria-label="select search content from "
            height="40px"
            value={searchSections}
            onChange={(e) => setSearchSections(e.target.value)}
            sx={{ padding: '0 12px' }}
          >
            <option value="all">All Content</option>
            <option value="group">Groups</option>
            <option value="post">Posts</option>
            <option value="course">Training</option>
            <option value="event">Events</option>
            {!tagsOnly && <option value="comment">Comments</option>}
          </Select>
          <Select
            width="22%"
            aria-label="select time period"
            height="40px"
            value={timeScope}
            onChange={(e) => setTimeScope(e.target.value)}
            sx={{ padding: '0 12px' }}
          >
            <option value={1}>Last 1 Day</option>
            <option value={14}>Last 2 Weeks</option>
            <option value={30}>Last 1 Month</option>
            <option value="all">All Time</option>
          </Select>
          <Spacer />
          <Button
            width="200px"
            colorScheme="blue"
            bg="blue.500"
            sx={{ ...theme.styles.global.button, fontWeight: '600', width: '308px' }}
            onClick={() => {
              setCurrentPageSearchResults(1);
              handleSearchClick();
            }}
            isDisabled={isSearchDisabled}
          >
            Submit
          </Button>
        </HStack>
      </Flex>
      {renderSearchResults(showTagsResult)}
    </Box>
  );
};

export default Search;
