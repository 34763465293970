/* eslint-disable no-unused-vars */
/* eslint-disable no-restricted-globals */
import React, { useRef, useState } from 'react';
import {
  Button,
  Popover,
  PopoverBody,
  PopoverContent,
  Select,
  VStack,
  Text,
  Input,
  useTheme,
  useDisclosure,
  IconButton,
} from '@chakra-ui/react';
import { NavLink, useLocation } from 'react-router-dom';
import CustomPopoverTrigger from '../ElementaryComponents/CustomPopoverTrigger';

const SearchPopOver = ({
  popOverIcon,
  showTimePeriod = true,
  setSearchQueryObj,
  sectionTitle,
  searchTerm,
  setSearchTerm,
  timePeriod,
  setTimePeriod,
  setSearchEntered,
  popOverTitle = 'Search in',
}) => {
  const theme = useTheme();
  const location = useLocation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const isLandingPage = location.pathname === '/dashboard';

  const initialFocusRef = useRef();

  const paramsObj = {
    query: searchTerm,
  };

  if (typeof +timePeriod === 'number') {
    paramsObj.last_created = timePeriod;
  } else {
    delete paramsObj.last_created;
  }

  const handleSearch = () => {
    setSearchQueryObj(paramsObj);
    setSearchEntered(true);
  };

  const handleClearSearch = () => {
    setSearchTerm('');
    setSearchQueryObj({});
    setSearchEntered(false);
    onClose();
  };

  return (
    <Popover
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      initialFocusRef={initialFocusRef}
    >
      <CustomPopoverTrigger
        isOpen={isOpen}
      >
        <IconButton
          sx={{ backgroundColor: 'rgba(256,256,256,0.2)' }}
          aria-label="Search"
          icon={popOverIcon}
        />
      </CustomPopoverTrigger>
      <PopoverContent style={{ position: 'absolute', right: '8px' }}>

        <PopoverBody _focus={{ outline: 'none' }} style={{ ...theme.styles.components.popOverStyle }} padding="16px">
          <VStack>
            <Text style={{ ...theme.styles.global.body, textAlign: 'left', width: '100%' }}>
              {popOverTitle}
              {' '}
              {sectionTitle}
            </Text>
            <Input
              placeholder="Type your search here"
              aria-label="search item"
              size="sm"
              borderRadius="4px"
              value={searchTerm}
              style={{ padding: '0 12px' }}
              onChange={(e) => setSearchTerm(e.target.value)}
              ref={initialFocusRef}
            />
            {showTimePeriod
              && (
                <Select
                  width="100%"
                  height="40px"
                  aria-label="select time period"
                  value={timePeriod}
                  onChange={(e) => setTimePeriod(e.target.value)}
                  style={{ padding: '0 12px' }}

                >
                  <option value={1}>Last 1 Day</option>
                  <option value={14}>Last 2 Weeks</option>
                  <option value={30}>Last 1 Month</option>
                  <option value="all">All Time</option>
                </Select>
              )}

            <Button
              height="40px"
              onClick={handleSearch}
              width="100%"
              colorScheme="blue"
              bg="blue.500"
            >
              Search
            </Button>
            <Button
              height="40px"
              onClick={handleClearSearch}
              width="100%"
              variant="outline"
            >
              Clear
            </Button>
            {
              isLandingPage && (
              <Button
                as={NavLink}
                to="navLink"
                width="100%"
                variant="outline"
                fontWeight="600"
                _hover={{ textDecoration: 'none' }}
              >
                Open
                {' '}
                  {sectionTitle}
                {'\u00A0'}
                Page
              </Button>
              )
            }
          </VStack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default SearchPopOver;
