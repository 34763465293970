/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useContext } from 'react';
import { useParams, NavLink } from 'react-router-dom';
import {
  VStack,
  HStack,
  Flex,
  useTheme,
  Text,
  Link,
  Box,
  Button,
  IconButton,
} from '@chakra-ui/react';
import pluralize from 'pluralize';
import { useSelector } from 'react-redux';
import { TEEAuthDataContext } from '@texas-education-exchange/edx-portal-shared';
import parse from 'html-react-parser';
import CreateCourseModal from '../Modals/CreateCourseModal';
import {
  VideocamIcon,
  InsertDriveFileIcon,
  SlideshowIcon,
  OpenInNewIcon,
  DownloadIcon,
  LinkIcon,
  InsertPhotoIcon,
} from '../../icons/index';
import SectionContainer from '../SectionContainer';
import DisplayTags from '../ElementaryComponents/DisplayTags';
import TogglePinIcon from '../ElementaryComponents/TogglePinIcon';
import Posts from '../DiscussionGroups/Posts';
import UserAvatar from '../ElementaryComponents/UserAvatar';
import {
  useFetchCourseByIdQuery,
  useGetPresignedUrlQuery,
  useReactToCourseMutation,
} from '../../services/apis/coursesAPI';
import StatusAlert from '../ElementaryComponents/StatusAlert';
import { useFetchAllPostsQuery } from '../../services/apis/postsAPI';
import DataUnavailable from '../ElementaryComponents/DataUnavailable';
import SectionFooter from '../NavMenu/SectionFooter';
import ProfileCardPopOver from '../PopOver/ProfileCardPopOver';
import { useFetchGroupByIdQuery } from '../../services/apis/groupsAPI';
import { formatLink, joinTruthyStrings } from '../../utils/helpers';
import DeleteModal from '../Modals/DeleteModal';
import ReactionIconComponent from '../ElementaryComponents/ReactionIconComponent';

const dropDownOptions = {
  Link: LinkIcon,
  Video: VideocamIcon,
  Slides: SlideshowIcon,
  Document: InsertDriveFileIcon,
  Images: InsertPhotoIcon,
};
const allFileTypes = ['Link', 'Video', 'Slides', 'Document', 'Images'];

const Course = ({ guidelines_accepted }) => {
  const theme = useTheme();
  const { course_id } = useParams();
  const { auth } = useContext(TEEAuthDataContext);
  const [course, setCourse] = useState([]);
  const [myPostsSortOrder, setMyPostsSortOrder] = useState('desc');
  const [selectedShowNumberPosts, setSelectedShowNumberPosts] = useState(3);
  const [currentPage, setCurrentPage] = useState(1);
  const [groupId, setGroupId] = useState(null);
  const [courseEdited, setCourseEdited] = useState(false);
  const [searchQueryObj, setSearchQueryObj] = useState({
    query: '',
    last_created: null,
  });
  const loggedInUser = useSelector((state) => state.auth.loggedInUser);
  const loggedInUserId = loggedInUser?.profile.sub;

  // const canEditandDelete = (auth?.user?.profile?.role ?? []).some((r) => {
  //   return ['Community.LeaAdmin', 'Community.EscAdmin', 'Community.ExchangeAdmin'].includes(r);
  // });
  // const [userPermission, setUserPermission] = useState({});

  // File Download Logic

  const [preSignedPath, setPreSignedPath] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFileName, setSelectedFileName] = useState('');
  const { data, isDataLoading } = useGetPresignedUrlQuery(selectedFile, {
    skip: !selectedFile,
  });

  const downloadFile = (url, fileName) => {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const objectURL = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = objectURL;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        a.remove();
      })
      .catch((error) => console.error('Error:', error));
  };

  const handleFileClick = (file, file_s3_url) => {
    const fileRequestType = 'fetch';
    const preSignedUrlPath = `${file_s3_url}&type=${fileRequestType}`;
    setSelectedFile(preSignedUrlPath);
    setSelectedFileName(file.file_name);
  };

  useEffect(() => {
    if (
      !isDataLoading
      && data?.signed_url
      && selectedFile
      && data.signed_url !== preSignedPath
    ) {
      downloadFile(data.signed_url, selectedFileName);
      setPreSignedPath(data.signed_url);
      setSelectedFile(null);
    }
  }, [data, isDataLoading, selectedFile, selectedFileName, preSignedPath]);

  const {
    data: courseData,
    isFetching: isCourseFetching,
    isError: isCourseError,
    error: courseError,
    isSuccess: isCourseFetched,
    refetch: refetchCourseData,
  } = useFetchCourseByIdQuery(course_id);

  useEffect(() => {
    if (courseData) {
      setGroupId(courseData?.data?.group_id);
    }
  }, [courseData]);

  const { data: groupData } = useFetchGroupByIdQuery(groupId, {
    skip: !groupId,
  });

  const postsQueryParams = {
    group_id: groupId,
    page_number: currentPage,
    page_size: selectedShowNumberPosts,
    sort_by: 'created_at',
    sort_order: myPostsSortOrder,
    query: searchQueryObj.query,
    last_created: searchQueryObj.last_created,
  };

  const {
    data: postsData,
    isFetching: isPostsFetching,
    isError: isPostsError,
    error: postsError,
  } = useFetchAllPostsQuery(postsQueryParams, { skip: !groupId });

  const [reactToCourse] = useReactToCourseMutation();
  const can_edit = courseData?.permissions?.course?.can_edit;
  const can_delete = courseData?.permissions?.course?.can_delete;

  const handlePostReaction = () => {
    reactToCourse({
      course_id,
      user_id: loggedInUserId,
      reaction_type: 'like',
    }).unwrap();
  };

  const handlePinChange = () => {
    refetchCourseData();
  };

  const courseComponent = () => {
    if (isCourseFetching) {
      return (
        <StatusAlert status="loading" alert_title="Fetching Course Data" />
      );
    }
    if (isCourseError) return <StatusAlert status="error" error={courseError} />;
    const {
      created_at,
      title,
      description,
      tags = [],
      reactions,
      duration,
      pin,
      pin_id,
      _id,
      author_id,
      course_material,
      author_body = {},
      group_id,
    } = courseData.data;

    const alreadyLiked = reactions?.like?.users.includes(loggedInUserId);

    const { files, links } = course_material || { files: [], links: [] };
    let authorName = joinTruthyStrings({
      strings: [author_body.first_name, author_body.last_name],
      connectingString: ' ',
    });
    if (!authorName) authorName = author_id;
    const isAuthor = loggedInUserId === author_id;

    const displayFiles = () => {
      if (files.length > 0) {
        return (
          <Flex flexDirection="column">
            {files.map((file, index) => {
              const { file_type, file_name, file_s3_url } = file;
              const FileTypeComponent = allFileTypes.includes(file_type)
                ? dropDownOptions[file_type]
                : InsertDriveFileIcon;
              return (
                <Button
                  key={file_name}
                  onClick={() => handleFileClick(file, file_s3_url)}
                  variant="outline"
                  border="none"
                  size="md"
                  color="blue.500"
                  width="max-content"
                >
                  <FileTypeComponent style={{ marginRight: '0.5rem' }} />
                  <Text style={{ ...theme.styles.global.body }}>
                    {file_name}
                  </Text>
                  <Text>
                    <DownloadIcon />
                  </Text>
                </Button>
              );
            })}
          </Flex>
        );
      }
      return null;
    };

    const displayLinks = () => {
      if (links?.length > 0) {
        return (
          <Flex flexDirection="column">
            {links.map((link) => {
              const { link_type, link_name, link_url } = link;
              const FileTypeComponent = allFileTypes.includes(link_type)
                ? dropDownOptions[link_type]
                : InsertDriveFileIcon;

              const formattedLink = formatLink(link_url);

              return (
                <Button
                  as={Link}
                  key={formattedLink + link_name}
                  href={formattedLink}
                  target="_blank"
                  variant="outline"
                  border="none"
                  size="md"
                  colorScheme="blue"
                  mt={0}
                  width="max-content"
                  justifyContent="flex-start"
                  isExternal
                >
                  <FileTypeComponent
                    style={{ marginRight: '0.5rem', marginTop: 0 }}
                  />
                  <Text
                    style={{
                      ...theme.styles.global.body,
                      textDecoration: 'none',
                    }}
                  >
                    {link_name}
                  </Text>
                  <Flex>
                    <OpenInNewIcon />
                  </Flex>
                </Button>
              );
            })}
          </Flex>
        );
      }
      return null;
    };
    // Create Pin Body
    const createPinBodyObj = {
      child_object_id: _id,
      child_object_type: 'course',
    };

    return (
      <>
        <VStack justifyContent="space-between" py={4} mb={8} alignItems="left">
          <NavLink
            to="/training"
            style={{
              ...theme.styles.global.h1,
              color: `${theme.colors.blue[800]}`,
              display: 'inline-block',
              width: 'max-content',
            }}
          >
            Exchange Trainings
          </NavLink>
          <Flex alignItems="center" justify="space-between" width="100%">
            <Text
              style={{ ...theme.styles.global.h3, marginTop: 8 }}
              color="blue.800"
            >
              {parse(title)}
            </Text>
            <Flex
              style={{
                marginLeft: 'auto',
                justifyContent: 'space-around',
                width: '150px',
                alignItems: 'center',
              }}
            >
              <Flex alignItems="center">
                <Text>{reactions?.like?.users?.length}</Text>
                <ReactionIconComponent
                  handleClick={() => handlePostReaction()}
                  alreadyLiked={alreadyLiked}
                  ariaLabelText="training"
                />
              </Flex>
              <TogglePinIcon
                is_pinned={pin}
                create_pin_body_obj={createPinBodyObj}
                pin_id={pin_id}
                updatePinState={handlePinChange}
              />
            </Flex>
          </Flex>
          <Flex mt={0}>
            <Text variant="details" mr={16}>
              {pluralize('reply', groupData?.data?.comment_count || 0, true)}
            </Text>
            <DisplayTags tags={tags} />
          </Flex>
        </VStack>

        {/* Course Section */}

        <VStack
          style={{
            ...theme.styles.global.container,
            width: '100%',
            alignItems: 'flex-start',
          }}
          p="1rem"
          bg="white"
          mb="3rem"
        >
          <Text style={{ ...theme.styles.global.h4 }}>Description</Text>
          <HStack alignItems="flex-start" justify="space-between" width="100%">
            <Flex flexDirection="column">
              <Text
                style={{
                  ...theme.styles.global.body,
                  fontWeight: 400,
                  color: '#000000',
                }}
                pr="2rem"
              >
                {parse(description)}
              </Text>
              <HStack>
                <Text
                  style={{ ...theme.styles.global.body, marginTop: '1rem' }}
                >
                  Time to Complete:
                </Text>
                <Text
                  style={{
                    ...theme.styles.global.body,
                    fontWeight: '400',
                    marginTop: '1rem',
                  }}
                >
                  {duration}
                  {' '}
                  hours
                </Text>
              </HStack>
              <Text style={{ ...theme.styles.global.h4, marginTop: '2rem' }}>
                Training Content
              </Text>
              <Flex
                justifyContent="space-between"
                mb={4}
                alignItems="left"
                flexDirection="column"
              >
                {displayFiles()}
                {displayLinks()}
              </Flex>
            </Flex>
            <Flex
              flexDirection="column"
              gap="1rem"
              minWidth="250px"
              p="1rem"
              pt={0}
              bg="white"
              mb="3rem"
            >
              <h6>AUTHOR</h6>
              <Flex alignItems="center" marginBottom="3rem">
                <ProfileCardPopOver
                  memberProfile={author_body}
                  userAvatar={
                    <UserAvatar name={authorName} user_profile_pic="" />
                  }
                />
                <Text style={{ ...theme.styles.global.body }} ml={2}>
                  {authorName}
                </Text>
              </Flex>

              <Flex justifyContent="center">
                {can_delete && guidelines_accepted && (
                  <DeleteModal
                    itemId={_id}
                    deleteType="course"
                    customBtnStyle={{
                      width: '108px',
                      marginRight: '0.5rem',
                      backgroundColor: theme.colors.red[600],
                      color: 'white',
                      border: `1px solid ${theme.colors.red[600]}`,
                    }}
                    size="sm"
                    variant="outline"
                  />
                )}
                {can_edit && guidelines_accepted && (
                  <CreateCourseModal
                    buttonLabel="Edit"
                    isEditing
                    courseData={courseData?.data}
                    title="My Modal"
                    customBtnStyle={{ width: '108px', marginRight: '0.5rem' }}
                    customSize="sm"
                  />
                )}
              </Flex>
              {/* <Link href="/training"
            style={{ ...theme.styles.global.body }} color="blue.500">Contact Author</Link> */}
            </Flex>
          </HStack>
        </VStack>
      </>
    );
  };

  const discussionComponent = () => {
    if (isPostsFetching) return <StatusAlert status="loading" alert_title="Fetching Posts Data" />;
    if (isPostsError) return <StatusAlert status="error" error={postsError} />;
    if (postsData.data.length === 0) {
      return (
        <DataUnavailable
          display_message={['There is no discussion added yet.']}
        />
      );
    }
    return (
      <>
        <Posts
          postsData={postsData}
          noDataMessage={['No data']}
          courseGroupId={groupId}
        />
        <SectionFooter
          selectedShowNumber={selectedShowNumberPosts}
          setSelectedShowNumber={setSelectedShowNumberPosts}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalCount={postsData?.meta?.total_pages}
        />
      </>
    );
  };

  return (
    <>
      {/* Discussion Section */}
      <HStack alignItems="center" justify="space-between" width="100%">
        <Box gridArea="Discussion" pt={0} pl={0} w="full">
          {courseComponent()}
          {groupId && guidelines_accepted && (
            <SectionContainer
              sectionTitle="Discussion"
              sectionInfoData={postsData?.data || []}
              setSortOrder={setMyPostsSortOrder}
              showPostModal="true"
              groupId={groupId}
              childComponent={discussionComponent()}
              searchIcon
              searchQueryObj={searchQueryObj}
              setSearchQueryObj={setSearchQueryObj}
            />
          )}
        </Box>
      </HStack>
    </>
  );
};

export default Course;
