/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect, useContext } from 'react';
import { useParams, NavLink } from 'react-router-dom';
import parse from 'html-react-parser';
import {
  VStack,
  Flex,
  useTheme,
  Text,
  HStack,
  Box,
  IconButton,
} from '@chakra-ui/react';
import pluralize from 'pluralize';
import { useSelector } from 'react-redux';
import { TEEAuthDataContext } from '@texas-education-exchange/edx-portal-shared';
import { MoreVertIcon } from '../../icons/index';
import DisplayTags from '../ElementaryComponents/DisplayTags';
import SectionHeader from '../Dashboard/SectionHeader';
import ReplyModal from '../Modals/ReplyModal';
import TogglePinIcon from '../ElementaryComponents/TogglePinIcon';
import EditPostModal from '../Modals/EditPostModal';
import {
  useFetchAllModerationReportsQuery,
  useFetchPostByIdQuery,
  useReactToPostMutation,
} from '../../services/apis/postsAPI';
import ActionPopOver from '../PopOver/ActionPopOver';
import {
  useAddReactionToCommentMutation,
  useFetchAllCommentsQuery,
  useFetchCommentByIdQuery,
} from '../../services/apis/commentsAPI';
import StatusAlert from '../ElementaryComponents/StatusAlert';
import UserAvatar from '../ElementaryComponents/UserAvatar';
import ProfileCardPopOver from '../PopOver/ProfileCardPopOver';
import DataUnavailable from '../ElementaryComponents/DataUnavailable';
import DeleteModal from '../Modals/DeleteModal';
import { useFetchGroupByIdQuery } from '../../services/apis/groupsAPI';
import {
  formatStatusText,
  getFormattedExactTime,
  joinTruthyStrings,
} from '../../utils/helpers';
import SectionFooter from '../NavMenu/SectionFooter';
import ReportContentModal from '../Modals/ReportContentModal';
import FlaggedItemTitle from '../ElementaryComponents/FlaggedItemTitle';
import FlaggedAuthorName from '../ElementaryComponents/FlaggedAuthorName';
import ReactionIcon from '../ElementaryComponents/ReactionIcon';
import SectionContainer from '../SectionContainer';

const Post = ({ no_action_in_group }) => {
  const theme = useTheme();
  const { group_id, post_id, comment_id } = useParams();
  const [selectedShowNumber, setSelectedShowNumber] = useState(5);
  const [commentsSortOrder, setCommentsSortOrder] = useState('desc');
  const [currentPage, setCurrentPage] = useState(1);
  const [pinUpdated, setPinUpdated] = useState(false);
  const [searchQueryObj, setSearchQueryObj] = useState({
    query: '',
    last_created: null,
  });
  const [fetchAllComments, setFetchAllComments] = useState(false);
  const [commentRank, setCommentRank] = useState(null);
  const { auth } = useContext(TEEAuthDataContext);
  const currentUserRoleList = auth?.user?.profile?.role;
  const isExchangeAdmin = currentUserRoleList?.includes(
    'Community.ExchangeAdmin',
  );
  const isCommunityUser = currentUserRoleList.includes('Community.User');

  const allCommentsQueryObj = {
    page_size: selectedShowNumber,
    page_number: currentPage,
    post_id,
    sort_by: 'created_at',
    sort_order: commentsSortOrder,
    query: searchQueryObj.query,
    last_created: searchQueryObj.last_created,
  };

  const commentByIdQueryObj = {
    page_size: selectedShowNumber,
    post_id,
    sort_by: 'created_at',
    sort_order: commentsSortOrder,
  };

  const loggedInUser = useSelector((state) => state.auth.loggedInUser);
  const loggedInUserId = loggedInUser?.profile.sub;

  // if (comment_id) {
  //   allCommentsQueryObj.comment_id = comment_id;
  // }

  const { data: groupData } = useFetchGroupByIdQuery(group_id);

  const {
    data: postData,
    isFetching: isPostFetching,
    isError: isPostError,
    error: postError,
    isSuccess: isPostFetched,
    refetch,
  } = useFetchPostByIdQuery(post_id);

  const {
    data: commentData,
    isFetching: isCommentFetching,
    isError: isCommentError,
    refetch: refetchComment,
  } = useFetchCommentByIdQuery({ comment_id, queryParam: commentByIdQueryObj }, { skip: !comment_id });

  useEffect(() => {
    if (!comment_id) {
      setFetchAllComments(true);
    } else if (commentData?.meta?.rank) {
      setCommentRank(commentData?.meta?.rank);
    }
  }, [comment_id, commentData]);

  useEffect(() => {
    if (commentRank) {
      const tempCurrentPage = Math.ceil(commentRank / selectedShowNumber);
      setCurrentPage(tempCurrentPage);
      setFetchAllComments(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commentRank]);

  const PostHasBeenDeleted = postError?.data?.message === 'Post not found';

  const {
    is_locked, is_hidden, user_locked, user_banned,
  } = groupData?.data || {};

  const can_view_hidden = groupData?.permissions?.group?.can_view_hidden;
  const { status, formatedStatusText, showFlaggedItemTitle } = formatStatusText(
    {
      can_view_hidden,
      includeStatusText: true,
      is_locked,
      is_hidden,
      user_locked,
      user_banned,
    },
  );

  // For Post
  const {
    is_locked: is_post_locked,
    is_hidden: is_post_hidden,
    user_locked: user_locked_in_post,
    user_banned: user_banned_in_post,
    author_banned: post_author_banned,
  } = postData?.data || {};

  const {
    status: post_status,
    formatedStatusText: post_formatedStatusText,
    showFlaggedItemTitle: post_showFlaggedItemTitle,
  } = formatStatusText({
    can_view_hidden,
    includeStatusText: true,
    is_locked: is_post_locked,
    is_hidden: is_post_hidden,
    user_locked: user_locked_in_post,
    user_banned: user_banned_in_post,
  });

  const post_permissions = postData?.permissions?.post;
  const can_moderate_post = post_permissions?.can_hide || post_permissions?.can_lock;
  const can_edit_post = post_permissions?.can_edit;
  const can_delete_post = post_permissions?.can_delete;

  const {
    data: commentsData,
    isFetching: isCommentsFetching,
    isError: isCommentsError,
    error: commentsError,
    isSuccess: isCommentsFetched,
  } = useFetchAllCommentsQuery(allCommentsQueryObj, { skip: !fetchAllComments });

  const comment_permissions = commentsData?.permissions?.comment;
  const can_moderate_comment = comment_permissions?.can_hide || comment_permissions?.can_lock;
  const can_delete_comment = comment_permissions?.can_delete;

  useEffect(() => {
    refetch();
    if (pinUpdated) setPinUpdated(false);
  }, [refetch, pinUpdated]);

  const no_action_under_post = is_post_locked || user_locked_in_post || post_author_banned;
  const no_action_allowed = no_action_in_group || no_action_under_post;

  const [reactToPost] = useReactToPostMutation();
  const [reactToComment] = useAddReactionToCommentMutation();

  const reportsQueryObj = {
    page_number: 1,
    page_size: 5,
    sort_by: 'created_at',
    sort_order: 'asc',
  };

  // report_id for the posts and comment if they have been report previously.
  const { data: postReportsData } = useFetchAllModerationReportsQuery({
    ...reportsQueryObj,
    object_id: post_id,
  });

  // Todo: fix this later :(
  const { data: commentReportsData } = useFetchAllModerationReportsQuery({
    ...reportsQueryObj,
    object_id: post_id,
  });
  const moderation_report_id_post = postReportsData?.data[0]?._id;
  const moderation_report_id_comment = commentReportsData?.data[0]?._id;

  const postComponenent = () => {
    if (isPostFetching) {
      return <StatusAlert status="loading" alert_title="Post Fetching..." />;
    }
    if (isPostError) return <StatusAlert status="error" error={postError} />;

    const {
      created_at,
      author_id,
      author_banned,
      author_locked,
      user_locked: user_locked_post,
      description,
      tags,
      title: post_title,
      author_body = {}, // may not exist in the post data
      reactions,
      pin,
      _id,
      pin_id,
    } = postData.data;

    const post_like_count = reactions?.like.users.length;

    const currentUserIndex = reactions?.like.users.indexOf(loggedInUserId);
    const alreadyLiked = currentUserIndex !== -1;
    let authorName = joinTruthyStrings({
      strings: [author_body.first_name, author_body.last_name],
      connectingString: ' ',
    });
    if (!authorName) authorName = author_id;
    const author_flagged = author_banned || author_locked;

    const createPinBodyObj = {
      parent_object_id: group_id,
      parent_object_type: 'group',
      child_object_id: _id,
      child_object_type: 'post',
    };

    const handlePostReaction = () => {
      reactToPost({
        post_id,
        reaction_type: 'like',
        user_id: loggedInUserId,
      }).unwrap();
    };

    return (

      <>
        <VStack justifyContent="space-between" py={4} mb={2} alignItems="left">
          <Flex alignItems="center" gap="1em" color="blue.800">
            <NavLink to="/forum">
              <h1>Discussion Groups</h1>
            </NavLink>
            <h1>/</h1>
            <NavLink to={`/forum/${group_id}`}>
              {showFlaggedItemTitle ? (
                <FlaggedItemTitle
                  customStyle={{ ...theme.styles.global.h1 }}
                  title={groupData?.data.title}
                  status={status}
                  formatedStatusText={formatedStatusText}
                />
              ) : (
                <h1>{parse(groupData?.data?.title || '')}</h1>
              )}
            </NavLink>
          </Flex>
          <Flex>
            {post_showFlaggedItemTitle ? (
              <FlaggedItemTitle
                customStyle={{ ...theme.styles.global.h3, marginTop: '0.5rem' }}
                title={post_title}
                status={post_status}
                formatedStatusText={post_formatedStatusText}
              />
            ) : (
              <h3>{parse(post_title)}</h3>
            )}
          </Flex>
          <Flex style={{ marginTop: '1rem' }}>
            <Text variant="details" mr={16}>
              {pluralize('reply', commentsData?.meta?.total_comments || 0, true)}
            </Text>
            <DisplayTags tags={tags} />
          </Flex>
        </VStack>

        {/* Post */}
        {author_banned && !can_view_hidden ? (
          <Text
            style={{
              ...theme.styles.global.container,
              padding: '2rem',
              textAlign: 'center',
              fontSize: '1.2rem',
              fontWeight: '600',
            }}
            color="red.700"
          >
            The creator of this content was banned from this group. This content
            is no longer visible, and not open to further replies.
          </Text>
        ) : (
          <VStack
            style={{
              ...theme.styles.global.container,
              width: '100%',
              alignItems: 'flex-start',
            }}
            p="1rem"
            bg="white"
            mb="2rem"
          >
            <HStack alignItems="center" justify="space-between" width="100%">
              <Flex alignItems="center" gap="0.5rem">
                {author_flagged ? (
                  <FlaggedAuthorName
                    author_name={authorName}
                    author_banned={author_banned}
                    author_locked={author_locked}
                  />
                ) : (
                  <>
                    <ProfileCardPopOver
                      memberProfile={author_body}
                      userAvatar={
                        <UserAvatar name={authorName} user_profile_pic="" />
                      }
                    />
                    <Text
                      style={{
                        fontWeight: '700',
                        display: 'inline-block',
                      }}
                    >
                      {authorName}
                      {' '}
                    </Text>
                  </>
                )}
                <Text variant="details">
                  posted at
                  {' '}
                  {getFormattedExactTime(created_at)}
                </Text>
              </Flex>
              <Flex
                style={{
                  marginLeft: 'auto',
                  justifyContent: 'space-between',
                  maxWidth: '150px',
                  alignItems: 'center',
                }}
              >
                <Box
                  style={{
                    display: 'flex',
                    height: '24px',
                    alignItems: 'center',
                  }}
                >
                  <Text w="30px" textAlign="right">
                    {post_like_count === 0 ? '' : post_like_count}
                  </Text>
                  <IconButton
                    aria-label={
                      alreadyLiked ? 'Unlike this post' : 'Like this post'
                    }
                    disabled={no_action_allowed}
                    onClick={() => (no_action_allowed ? '' : handlePostReaction())}
                    style={{
                      cursor: no_action_allowed ? 'not-allowed' : 'pointer',
                      width: '40px',
                    }}
                    icon={(
                      <ReactionIcon
                        isLiked={alreadyLiked && !no_action_allowed}
                      />
                    )}
                  />
                </Box>
                <TogglePinIcon
                  is_pinned={pin}
                  create_pin_body_obj={createPinBodyObj}
                  pin_id={pin_id}
                  updatePinState={() => setPinUpdated(true)}
                />
                {!no_action_allowed && (
                  <ActionPopOver
                    moreVertPopOver={<MoreVertIcon cursor="pointer" />}
                    content_type="post"
                    content_id={post_id}
                  />
                )}
              </Flex>
            </HStack>
            {description && (
              <Text
                as="div"
                whiteSpace="pre-wrap"
                className="overRide-bulletPoint-style"
                variant="post"
              >
                {parse(description)}
              </Text>
            )}
            <Flex alignSelf="flex-end" gap={2}>
              {loggedInUserId === author_id
                && !no_action_allowed
                && can_edit_post && (
                  <EditPostModal
                    postID={_id}
                    buttonLabel="Edit"
                    title="My Modal"
                    customBtnStyle={{ width: '108px' }}
                    size="sm"
                    postData={postData.data}
                    variant="outline"
                  />
              )}
              {((loggedInUserId === author_id
                && !no_action_allowed
                && can_delete_post)
                || isExchangeAdmin) && (
                <DeleteModal
                  itemId={_id}
                  deleteType="post"
                  customBtnStyle={{ width: '108px' }}
                  size="sm"
                  variant="outline"
                />
              )}
              {!no_action_allowed && (
                <ReplyModal
                  buttonLabel="Reply to Post"
                  title="My Modal"
                  customBtnStyle={{ width: '108px' }}
                  size="sm"
                  data={postData.data}
                />
              )}
              {can_moderate_post && (
                <ReportContentModal
                  moderate_btn
                  content_type="post"
                  content_id={post_id}
                  isModerator
                  moderation_report_id={moderation_report_id_post}
                  customBtnStyle={{
                    backgroundColor: theme.colors.red[600],
                    color: 'white',
                    border: `1px solid ${theme.colors.red[600]}`,
                    width: '108px',
                  }}
                />
              )}
            </Flex>
          </VStack>
        )}
      </>
    );
  };

  const commentsComponents = () => {
    if (isCommentsFetching) {
      return (
        <StatusAlert status="loading" alert_title="Comments Fetching..." />
      );
    }
    if (isCommentsError) {
      return <StatusAlert status="error" error={commentsError} />;
    }
    if (commentsData?.data?.length === 0) {
      return (
        <DataUnavailable
          display_message={[
            'There is no discussion added yet. Reply to the above post to see the comments',
          ]}
        />
      );
    }
    const handleCommentReaction = (comment_id_display) => {
      reactToComment({
        post_id,
        comment_id: comment_id_display,
        reaction_type: 'like',
        user_id: loggedInUserId,
        queryParams: allCommentsQueryObj,
      }).unwrap();
    };
    return (
      <>
        <VStack
          style={{
            ...theme.styles.global.container,
            width: '100%',
            alignItems: 'flex-start',
          }}
          bg="white"
          mb="3rem"
        >
          {commentsData?.data.map((comment, index) => {
            const {
              title: reply_title,
              reactions,
              _id: comment_id_display,
              created_at: replied_at,
              author_body: comment_author_body,
              quoted_user_id,
              quoted_comment_body = {},
              is_content_hidden,
              author_banned,
              author_locked,
            } = comment;

            const HiddenQuote = quoted_comment_body?.is_content_hidden;

            const currentUserIndex = reactions?.like.users.indexOf(loggedInUserId);
            const alreadyLiked = currentUserIndex !== -1;

            const comment_like_count = reactions?.like.users.length;
            const isQuotedComment = quoted_user_id.length > 0;
            const replyCardStyle = index !== (commentsData?.data?.length || 1) - 1
              ? { borderBottom: '1px solid #CED8DD', paddingBottom: '1rem' }
              : {};

            const selectedComment = comment_id === comment_id_display ? { backgroundColor: '#ddf4ff' } : {};

            const commentAuthor = [
              comment_author_body.first_name,
              comment_author_body.last_name,
            ]
              .filter((n) => n)
              .join(' ');

            return (
              isCommunityUser && author_banned ? null : (
                <VStack
                  key={comment_id_display}
                  tabIndex={0}
                  style={{
                    ...replyCardStyle,
                    width: '100%',
                    alignItems: 'flex-start',
                    outline: 'none',
                    ...selectedComment,
                  }}
                  p="1rem"
                >
                  <HStack
                    alignItems="center"
                    justify="space-between"
                    width="100%"
                  >
                    <Flex alignItems="center" gap="0.5rem">
                      {!author_banned && (
                      <ProfileCardPopOver
                        memberProfile={comment_author_body}
                        userAvatar={
                          <UserAvatar name={commentAuthor} user_profile_pic="" />
                      }
                      />
                      )}
                      {(author_banned || author_locked) ? (
                        <FlaggedAuthorName
                          author_name={commentAuthor}
                          author_banned={author_banned}
                          author_locked={author_locked}
                        />
                      ) : (
                        <Text
                          style={{
                            fontWeight: '700',
                            display: 'inline-block',
                          }}
                        >
                          {commentAuthor}
                        </Text>
                      )}
                      <Text variant="details">
                        {' '}
                        replied at
                        {' '}
                        {getFormattedExactTime(replied_at)}
                      </Text>
                      {is_content_hidden && (
                      <Text
                        style={{
                          fontWeight: 700,
                          color: theme.colors.red[700],
                        }}
                      >
                        This Content Is Hidden
                      </Text>
                      )}
                    </Flex>
                    <Flex
                      style={{
                        marginLeft: 'auto',
                        justifyContent: 'space-around',
                        width: '100px',
                        alignItems: 'center',
                      }}
                    >
                      <Box
                        style={{
                          display: 'flex',
                          height: '24px',
                          alignItems: 'center',
                        }}
                      >
                        <Text w="30px" textAlign="right">
                          {comment_like_count > 0 ? comment_like_count : ''}
                        </Text>
                        <IconButton
                          aria-label={
                          alreadyLiked
                            ? 'Unlike this comment'
                            : 'Like this comment'
                        }
                          disabled={no_action_allowed}
                          onClick={() => (no_action_allowed
                            ? ''
                            : handleCommentReaction(comment_id_display))}
                          style={{
                            cursor: no_action_allowed ? 'not-allowed' : 'pointer',
                            width: '40px',
                          }}
                          icon={(
                            <ReactionIcon
                              isLiked={alreadyLiked && !no_action_allowed}
                            />
                        )}
                        />
                      </Box>
                      {!no_action_allowed && (
                      <ActionPopOver
                        moreVertPopOver={<MoreVertIcon cursor="pointer" />}
                        content_type="comment"
                        content_id={comment_id_display}
                      />
                      )}
                    </Flex>
                  </HStack>
                  {isQuotedComment && (
                  <Box as="div" mb="1rem" width="100%">
                    <Box
                      backgroundColor="#EFF4F6"
                      p={4}
                      mr={0}
                      mt={2}
                      color="gray.600"
                      textAlign="left"
                      fontWeight="700"
                    >
                      {(!HiddenQuote || can_view_hidden) && (
                      <>
                        <Text fontWeight="bold" display="inline-block">
                          {quoted_comment_body.author_name}
                          :
                        </Text>
                        <Text lineHeight="22px">
                          {parse(quoted_comment_body?.comment_title || '')}
                        </Text>
                      </>
                      )}
                      {HiddenQuote && !can_view_hidden && (
                      <Text
                        style={{
                          fontWeight: 700,
                          color: theme.colors.red[700],
                        }}
                      >
                        This Content Is Hidden
                      </Text>
                      )}
                    </Box>
                  </Box>
                  )}
                  {reply_title && (
                  <Flex style={{ marginTop: '1rem' }}>
                    <Text as="div" variant="post">
                      {parse(reply_title)}
                    </Text>
                  </Flex>
                  )}
                  {/* Reply Modal */}
                  <Flex m="2rem 0" alignSelf="flex-end" gap=".5rem">
                    {/* {loggedInUserId === comment_author_body._id
                    && !no_action_allowed && (
                      <ReplyModal
                        postID="_id"
                        buttonLabel="Edit"
                        title="My Modal"
                        size="sm"
                        data={postData?.data}
                        variant="primary"
                        isEditingComment
                        commentData={comment}
                      />
                    )} */}
                    {((loggedInUserId === comment_author_body._id
                    && !no_action_allowed
                    && can_delete_comment)
                    || isExchangeAdmin) && (
                    <DeleteModal
                      itemId={comment_id_display}
                      deleteType="comment"
                      variant="outline"
                      size="sm"
                      customBtnStyle={{ width: '108px' }}
                    />
                    )}
                    {!no_action_allowed && (
                    <ReplyModal
                      buttonLabel="Reply to Comment"
                      size="sm"
                      data={comment}
                      variant="outline"
                      comment_author={`${comment_author_body.first_name} ${comment_author_body.last_name}`}
                      comment_quote={reply_title}
                    />
                    )}
                    {can_moderate_comment && (
                    <ReportContentModal
                      moderate_btn
                      content_type="comment"
                      content_id={comment_id_display}
                      isModerator
                      moderation_report_id=""
                      customBtnStyle={{
                        color: theme.colors.red[600],
                        backgroundColor: 'white',
                        border: `1px solid ${theme.colors.red[600]}`,
                        width: '108px',
                      }}
                    />
                    )}
                  </Flex>
                </VStack>
              )
            );
          })}
        </VStack>
        <SectionFooter
          selectedShowNumber={selectedShowNumber}
          setSelectedShowNumber={setSelectedShowNumber}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalCount={commentsData?.meta?.total_pages}
        />
      </>
    );
  };

  return (
    <>
      { PostHasBeenDeleted && (
      <StatusAlert status="error" alert_title="This post was either deleted or is currently unavailable." />
      )}
      {isPostFetched && postComponenent()}
      <SectionContainer
        setSortOrder={setCommentsSortOrder}
        totalCount={0}
        searchIcon
        sectionTitle="Replies"
        searchQueryObj={searchQueryObj}
        setSearchQueryObj={setSearchQueryObj}
      />

      {/* Replies */}
      {isCommentsFetched
        && !user_banned
        && (!post_author_banned || can_view_hidden)
        && !isPostFetching
        && !isPostError && (
          <Box ml="2rem">
            {commentsComponents()}
          </Box>
      )}
    </>
  );
};

export default Post;
