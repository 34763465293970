import React from 'react';
import { PopoverTrigger } from '@chakra-ui/react';

const CustomPopoverTrigger = ({ isOpen, children }) => {
  return (
    isOpen ? (
      <>
        <PopoverTrigger>
          <span>{ }</span>
        </PopoverTrigger>
        {children}
      </>
    )
      : (
        <PopoverTrigger>
          {children}
        </PopoverTrigger>
      )
  );
};

export default CustomPopoverTrigger;
