/* eslint-disable no-unused-vars */
import {
  Box,
  Flex, Spacer, Text, useTheme,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import parse from 'html-react-parser';
import { useNavigate, useParams } from 'react-router-dom';
import UserAvatar from '../ElementaryComponents/UserAvatar';
import ProfileCardPopOver from '../PopOver/ProfileCardPopOver';
import { getFormattedExactTime, joinTruthyStrings, capitalizeFirstLetter } from '../../utils/helpers';
import { useFetchModerationReportByIdQuery } from '../../services/apis/postsAPI';
import StatusAlert from '../ElementaryComponents/StatusAlert';
import DataUnavailable from '../ElementaryComponents/DataUnavailable';
import ModerateModal from '../Modals/ModerateModal';
import ModerationActions from '../ElementaryComponents/ModerationActions';

const customActionTitle = {
  group: 'created at',
  post: 'posted at',
  comment: 'replied at',
};

const ContentBlock = ({
  contentData = {},
  index,
  showTimestamp,
}) => {
  const theme = useTheme();
  const { title, description, added_at } = contentData;
  return (
    <Flex key={added_at} flexDirection="column" mt={4}>
      {showTimestamp && (
        <Text as="span" style={{ ...theme.styles.global.body2, display: 'inline-block' }} mb={1}>
          {index === 0 ? 'Original' : 'Edit'}
          {' '}
          -
          {' '}
          {getFormattedExactTime(added_at)}
        </Text>
      )}
      <Box p={2} backgroundColor="gray.200" color="gray.800" className="overRide-bulletPoint-style">
        {title && (
          <Text mb={1}>
            <b>{parse(title)}</b>
          </Text>
        )}
        {description && (
          <Text>
            {parse(description)}
          </Text>
        )}
      </Box>
    </Flex>
  );
};

const Report = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { report_id } = useParams();
  const [showEditHistory, setShowEditHistory] = useState(false);
  const {
    data: reportData,
    isFetching: isFetchingReport,
    isError: isReportError,
    error: reportError,
  } = useFetchModerationReportByIdQuery(report_id);

  if (isFetchingReport) return <StatusAlert status="loading" alert_title="Fetching Report!" />;
  if (isReportError) return <StatusAlert status="error" error={reportError} />;

  if (!reportData?.data) {
    return <DataUnavailable display_message={['No moderation requests availabe']} />;
  }

  const { title = '', moderation = [], report_count } = reportData?.data || {};
  const permissions = reportData?.permissions;
  const {
    object_author_body = {}, object_body = {}, object_type = {}, object_id,
  } = moderation;
  const content_history = object_body[`${object_type}_history`];

  const titlePrefix = `${object_type.toUpperCase()} REPORTED - `;

  const authorName = joinTruthyStrings({
    strings: [object_author_body.first_name, object_author_body.last_name],
    connectingString: ' ',
  });

  const isInactive = !object_body?.active;

  const getContextPath = () => {
    let contextPath = '/forum';

    if (object_type === 'group') {
      contextPath += `/${object_id}`;
    } else if (object_type === 'post') {
      contextPath += `/${object_body?.group_id}/${object_id}`;
    } else if (object_type === 'comment') {
      contextPath += `/${object_body?.group_id}/${object_body?.post_id}/${object_id}`;
    } else {
      contextPath = '/reports';
    }

    return contextPath;
  };

  return (
    <Flex flexDirection="column">
      <Text mb={4} style={{ ...theme.styles.global.h3 }} className="forceFontStyleH3">
        {object_type === 'comment' ? parse(title) : parse(title)}
      </Text>
      <Flex
        style={{ ...theme.styles.global.container }}
        flexDirection="column"
        p={4}
        textAlign="left"
      >
        <Flex alignItems="center" gap={2}>
          <ProfileCardPopOver
            memberProfile={object_author_body}
            userAvatar={<UserAvatar name={authorName} user_profile_pic="" />}
          />
          <Text
            style={{
              fontWeight: '700',
              display: 'inline-block',
            }}
          >
            {authorName}
          </Text>
          <Text variant="details">
            {' '}
            {customActionTitle[object_type]}
            {' '}
            {getFormattedExactTime(object_body?.created_at)}
          </Text>

          <Spacer />
          {!isInactive && (
          <Text
            as="button"
            onClick={() => navigate(getContextPath())}
            style={{
              width: 'max-content',
              ...theme.styles.global.body2,
              fontWeight: 700,
            }}
            color="blue.500"
          >
            See in context
          </Text>
          )}

        </Flex>
        {Array.isArray(content_history) && content_history.length > 0 && (
          <Flex flexDirection="column">
            {
              showEditHistory ? (
                content_history.map((contentData, index) => (
                  <ContentBlock
                    key={contentData?.added_at}
                    contentData={contentData}
                    index={index}
                    showTimestamp
                  />
                ))
              ) : (
                <ContentBlock
                  contentData={content_history[0]}
                  index={0}
                  showTimestamp={content_history.length > 1}
                />
              )
}
          </Flex>
        )}
        {Array.isArray(content_history) && content_history.length > 1 && (
        <Flex>

          <Text
            as="button"
            onClick={() => setShowEditHistory(!showEditHistory)}
            style={{
              width: 'max-content',
              ...theme.styles.global.body2,
              fontWeight: 700,
            }}
            color="blue.500"
            mt={2}
          >
            {showEditHistory ? 'Hide' : 'Show'}
            {' '}
            Edits
          </Text>

        </Flex>
        )}
        {/* Users who have reported the content */}
        <Flex flexDirection="column" mt={4}>
          <Text style={{ ...theme.styles.global.h6 }} pb={2}>
            Requests for Moderation:
            {' '}
            <Text as="span" style={{ fontWeight: 700 }} color="red.700">
              {report_count}
            </Text>
          </Text>
          <Flex
            flexDirection="column"
            maxHeight="300px"
            overflowY="scroll"
            borderBottom="1px solid #CED8DD"
            p={4}
          >
            {moderation.report.map((modReport, index) => {
              const {
                user_id, reason, report_comment = '', added_at, author_body: report_author_body,
              } = modReport;

              const reportAuthorName = joinTruthyStrings({
                strings: [report_author_body.first_name, report_author_body.last_name],
                connectingString: ' ',
              });

              return (
                <Flex
                  className="postCard"
                  flexDirection="column"
                  key={user_id + added_at}
                  tabIndex={0}
                  gap={2}
                  pb={4}
                  width="100%"
                >
                  <Flex alignItems="center" justifyContent="space-between">
                    <Flex alignItems="center" gap={2}>
                      <ProfileCardPopOver
                        memberProfile={report_author_body}
                        userAvatar={<UserAvatar name={reportAuthorName} user_profile_pic="" />}
                      />
                      <Text
                        style={{
                          fontWeight: '700',
                          display: 'inline-block',
                        }}
                      >
                        {reportAuthorName}
                      </Text>
                    </Flex>
                    <Flex alignItems="center" gap={2}>
                      <Text style={{ ...theme.styles.global.body, fontWeight: 700 }}>
                        {capitalizeFirstLetter(reason)}
                      </Text>
                      <Text variant="details">
                        {getFormattedExactTime(added_at)}
                      </Text>
                    </Flex>
                  </Flex>
                  <Text style={{
                    ...theme.styles.global.body2, lineHeight: '20px', fontSize: '14px',
                  }}
                  >
                    {parse(report_comment || '')}
                  </Text>
                </Flex>
              );
            })}
          </Flex>
        </Flex>

        {/* Moderation Actions */}
        <ModerationActions
          moderation={moderation}
          customStyle={{ marginLeft: 0, paddingTop: '1rem' }}
          display_comment
        />

        <Flex gap="8px" alignItems="center" justifyContent="flex-end" mt={6}>
          { isInactive && (
            <Text>
              This item has been deleted. No further action can be taken on it.
            </Text>
          )}
          <ModerateModal
            isDisabled={isInactive}
            permissions={permissions}
            title={title}
            reportData={reportData}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};
export default Report;
