import React, {
  useEffect, useState,
} from 'react';
import { Text, Flex, useTheme } from '@chakra-ui/react';
import { NavLink, useLocation, useParams } from 'react-router-dom';
import pluralize from 'pluralize';
import parse from 'html-react-parser';
import DisplayTags from '../ElementaryComponents/DisplayTags';
import TogglePinIcon from '../ElementaryComponents/TogglePinIcon';
import PostModal from '../Modals/CreatePostModal';
import { useFetchGroupByIdQuery } from '../../services/apis/groupsAPI';
import StatusAlert from '../ElementaryComponents/StatusAlert';
import EditGroupModal from '../Modals/EditGroupModal';
import { GroupIcon } from '../../icons/index';
import GroupDetailsPopOver from '../PopOver/GroupDetailsPopOver';
import DeletePostModal from '../Modals/DeleteModal';
import FlaggedItemTitle from '../ElementaryComponents/FlaggedItemTitle';
import ReportContentModal from '../Modals/ReportContentModal';
import { useFetchAllModerationReportsQuery } from '../../services/apis/postsAPI';
import { formatStatusText } from '../../utils/helpers';

const groupStatusText = {
  is_locked: {
    title: 'This group was locked by a moderator or admin.',
    description: 'When a group is locked, you can see the content in a group, but you cannot interact with it. ',
  },
  is_hidden: {
    title: 'This content was removed by a moderator or admin',
    description: 'When a content item has been removed, you cannot see or interact with it.',
  },
  user_locked: {
    title: 'Your participation in this group was locked by a moderator or admin.',
    description: 'When you are locked, you can see the content in a group, but you cannot interact with it. Any content that belonged to you in this group is still present. If you feel this is in error, you may appeal to the moderators by pressing the Appeal button above. ',
  },
  user_banned: {
    title: 'You have been banned from this group by a moderator or admin',
    description: 'When you are banned from a group, you cannot see or interact with it. Any content that belonged to you in this group has been removed. If you feel this is in error, you may appeal to the moderators on the ',
  },
};

const PostsSectionSubHeader = ({ canCreatePost }) => {
  const { group_id } = useParams();
  const theme = useTheme();
  const location = useLocation();
  const [pinUpdated, setPinUpdated] = useState(false);
  const {
    data, isFetching, isError, refetch,
  } = useFetchGroupByIdQuery(group_id);

  // All reports for a group by group_id
  const reportsQueryObj = {
    page_number: 1,
    page_size: 5,
    sort_by: 'created_at',
    sort_order: 'asc',
    object_id: group_id,
  };
  const { data: groupReportsData } = useFetchAllModerationReportsQuery(reportsQueryObj);
  const moderation_report_id = groupReportsData?.data[0]?._id;

  useEffect(() => {
    refetch();
    if (pinUpdated) setPinUpdated(false);
  }, [refetch, location.pathname, pinUpdated]);

  if (isFetching) {
    return (
      <StatusAlert status="loading" alert_title="Fetching Group Details..." />
    );
  }
  if (isError) return <StatusAlert status="error" error={Error} />;

  const {
    title,
    pin,
    _id,
    app_id = null,
    course_id = null,
    post_count,
    description,
    comment_count = 0,
    tags,
    is_locked,
    is_hidden,
    user_locked,
    user_banned,
  } = data.data;

  const trainingNotDeleted = course_id && !title.includes('(COURSE DELETED)');

  const groupCanNotBeDeleted = app_id;
  const group_permissions = data?.permissions?.group ?? {};
  const {
    can_lock, can_hide, can_edit, can_view_hidden, can_delete,
  } = group_permissions;
  const { status, formatedStatusText, showFlaggedItemTitle } = formatStatusText(
    {
      can_view_hidden,
      includeStatusText: true,
      is_locked,
      is_hidden,
      user_locked,
      user_banned,
    },
  );

  let groupStatusObj;
  if (user_banned) {
    groupStatusObj = groupStatusText.user_banned;
  } else if (is_hidden) {
    groupStatusObj = groupStatusText.is_hidden;
  } else if (user_locked) {
    groupStatusObj = groupStatusText.user_locked;
  } else if (is_locked) {
    groupStatusObj = groupStatusText.is_locked;
  }

  const createPinBodyObj = {
    child_object_id: _id,
    child_object_type: 'group',
  };

  const bannedUserCardStyle = user_banned ? { ...theme.styles.global.container, padding: '1rem' } : {};

  const can_create_post = !user_banned && canCreatePost;
  const can_edit_group = !(user_banned || user_locked || is_locked) && can_edit;
  const can_moderate_group = !user_banned && (can_lock || can_hide);
  const can_delete_group = !user_banned && can_delete && !groupCanNotBeDeleted;

  return (
    <>
      <Flex justifyContent="space-between" alignItems="center" pb={4}>
        {
          showFlaggedItemTitle ? (
            <FlaggedItemTitle
              customStyle={{ ...theme.styles.global.h3 }}
              title={title}
              status={status}
              formatedStatusText={formatedStatusText}
            />
          ) : (
            <NavLink
              to="/forum"
              style={{ ...theme.styles.global.h3 }}
              color="blue.800"
            >
              {parse(title)}
            </NavLink>
          )
        }
        <Flex alignItems="center" justifyContent="space-between" gap={2}>
          {/* Manage Moderators */}
          { !user_banned && !user_locked && (
          <GroupDetailsPopOver
            triggerComp={<GroupIcon />}
            groupData={data}
            user_banned={user_banned}
          />
          )}
          <TogglePinIcon
            is_pinned={pin}
            create_pin_body_obj={createPinBodyObj}
            pin_id={data.data?.pin_id}
            updatePinState={() => setPinUpdated(true)}
          />
          {
            can_edit_group && (
            <EditGroupModal
              buttonLabel="Edit"
              title="My Modal"
              customBtnStyle={{ width: '108px' }}
              groupData={data.data}
              variant="outline"
            />
            )
          }
          {
            (status === 'NONE') && can_create_post
            && (
            <PostModal
              buttonLabel="New Post"
              customBtnStyle={{ width: '108px' }}
            />
            )
          }
          {
            can_delete_group
            && (
            <DeletePostModal
              itemId={group_id}
              deleteType="group"
              variant="outline"
              customBtnStyle={{ width: '108px' }}
              trainingNotDeleted={trainingNotDeleted}
            />
            )
          }
          {
            can_moderate_group && (
              <ReportContentModal
                moderate_btn
                content_type="group"
                content_id={group_id}
                isModerator
                moderation_report_id={moderation_report_id}
                customBtnStyle={{
                  backgroundColor: theme.colors.red[600],
                  color: 'white',
                  border: `1px solid ${theme.colors.red[600]}`,
                }}
              />
            )
          }
        </Flex>
      </Flex>
      {
        !user_banned && (
        <Flex width="100%" mb="1rem" gap="0.5rem">
          <Text variant="details">{pluralize('post', post_count, true)}</Text>
          <Text variant="details">{pluralize('reply', comment_count, true)}</Text>
          <DisplayTags tags={tags} />
        </Flex>
        )
      }
      <Flex mb={4} pr={2} flexDir="column" gap={4} style={bannedUserCardStyle}>
        {!user_banned && (
        <Text
          style={{
            ...theme.styles.global.body,
            fontStyle: 'italic',
            color: `${theme.colors.blue[800]}`,
            fontWeight: '400',
          }}
        >
          {parse(description)}
        </Text>
        )}
        <Text style={{ ...theme.styles.global.h5 }} color={user_banned ? 'black' : 'blue.800'}>
          {groupStatusObj?.title}
        </Text>
        <Text
          style={{
            ...theme.styles.global.h5,
            fontWeight: 400,
            fontSize: '16px',
            lineHeight: '20px',
          }}
          color={user_banned ? 'gray.600' : 'blue.800'}
        >
          {groupStatusObj?.description}
          {user_banned && (
          <NavLink
            to="/infractions"
            style={{
              ...theme.styles.global.body,
              color: `${theme.colors.blue[500]}`,
              fontWeight: '700',
            }}
          >
            infractions page.
          </NavLink>
          )}
        </Text>
      </Flex>
    </>
  );
};

export default PostsSectionSubHeader;
