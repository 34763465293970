import React from 'react';
import { NavLink } from 'react-router-dom';
import {
  Box,
  Flex,
  Text,
  useTheme,
} from '@chakra-ui/react';
import GuidelinesModal from '../Modals/GuidelinesModal';

const LeftSideBarIcon = (props) => {
  const {
    menu_item,
    path_name = null,
    menu_icon,
    navExpanded,
    setNavExpanded,
    sub_items = [],
    guidelines_accepted,
    latest_guidelines_status,
  } = props;

  const theme = useTheme();
  const hasChildren = sub_items?.length > 0;

  const subLinkStyle = {
    ...theme.styles?.global?.body2,
    color: '#fff',
  };

  return (
    <Box
      borderBottom="1px solid rgba(255, 255, 255, 0.4)"
      color="#fff"
      padding="0.5rem 0"
    >
      <NavLink
        className={hasChildren ? 'leftNavWithChildren' : 'leftNavLink'}
        style={{ cursor: hasChildren ? 'default' : 'pointer' }}
        to={path_name}
        aria-label={menu_item}
        onClick={(e) => {
          e.stopPropagation();
          if (sub_items.length && !navExpanded) {
            setNavExpanded(true);
          }
        }}
      >
        <Flex
          alignItems="center"
          justifyContent={navExpanded ? 'flex-start' : 'center'}
          gap={navExpanded ? '0.5rem' : 0}
        >
          {menu_icon}
          <Text as="p" color="#fff">{navExpanded && menu_item}</Text>
        </Flex>
      </NavLink>
      {
        navExpanded && sub_items.length > 0 && sub_items.map((si) => {
          if (si.menu_item === 'Community Guidelines') {
            return (
              <GuidelinesModal
                key={si.menu_item}
                onClick={(e) => {
                  e.stopPropagation();
                }}
                guidelines_accepted={guidelines_accepted}
                btn_type="text"
                latest_guidelines_status={latest_guidelines_status}
                buttonClass="leftNavChild"
              />
            );
          }
          if (guidelines_accepted) {
            return (
              <NavLink
                to={si.path_name}
                onClick={(e) => {
                  e.stopPropagation();
                }}
                key={si.menu_item}
                aria-label={si.menu_item}
                className="leftNavChild"
              >
                <Text as="p" style={{ ...subLinkStyle }}>{si.menu_item}</Text>
              </NavLink>
            );
          }
          return null;
        })
}
    </Box>
  );
};

export default LeftSideBarIcon;
