/* eslint-disable max-len */
import React from 'react';
import {
  Button,
  Flex,
  Text,
  Popover,
  PopoverBody,
  PopoverContent,
  useTheme,
  Box,
  useDisclosure,
  PopoverArrow,
} from '@chakra-ui/react';
import CustomPopoverTrigger from '../ElementaryComponents/CustomPopoverTrigger';
import DisplayTags from '../ElementaryComponents/DisplayTags';

const ProfileCard = ({ userAvatar, memberProfile = {}, customAvatarSize }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const {
    first_name = '',
    last_name = '',
    email,
    mini_bio = '',
    department = '',
    tags = [],
    job_title = '',
    organization = '',
  } = memberProfile || {};

  const theme = useTheme();
  // const handleButtonClick = (e) => {
  //   e.stopPropagation();

  // };

  const handlePopoverClick = (e) => {
    e.stopPropagation();
  };

  return (
    <Popover
      placement="left"
      onOpen={onOpen}
      onClose={onClose}
    >
      <Box>
        <CustomPopoverTrigger
          aria-label={`${first_name} ${last_name}`}
          isOpen={isOpen}
        >
          <Button
            size="45px"
            overflow="hidden"
            border="1px solid #849299"
            cursor="pointer"
            borderRadius="50%"
            width={customAvatarSize}
            height={customAvatarSize}
            onClick={handlePopoverClick}
            onKeyDown={(e) => e.stopPropagation()}
          >
            {userAvatar}
          </Button>
        </CustomPopoverTrigger>
      </Box>

      <PopoverContent
        onClick={handlePopoverClick}
        onKeyDown={(e) => e.stopPropagation()}
        style={{
          width: '260px',
          alignItems: 'left',
          textAlign: 'left',
        }}
      >
        <PopoverArrow borderWidth="0.25rem" bg="white" borderColor="white" style={{ marginTop: '1rem' }} />
        <PopoverBody style={{ ...theme.styles.components.popOverStyle, width: '100%', zIndex: 1 }} padding="16px" cursor="default">
          {!memberProfile && <Box>No profile data </Box>}
          {memberProfile && (
          <Box>

            <Text
              {...theme.styles.global.h4}
              color="#1E2D36"
              mb="0.25rem"
            >
              {[
                first_name,
                last_name,
              ].filter((n) => n).join(' ')}
            </Text>

            <Flex
              flexDirection="column"
              gap="1rem"
              mb="1.5rem"
            >
              <Flex
                flexDirection="column"
                gap="0.5rem"
              >
                {[
                  email,
                  organization,
                  department,
                  job_title,
                ].filter((t) => t).map((t) => (
                  <Text
                    key={t}
                    {...theme.styles.global.body2}
                  >
                    {t}
                  </Text>
                ))}
              </Flex>

              {tags?.length !== 0 && (
              <DisplayTags tags={tags} />
              )}

              <Text
                {...theme.styles.global.body2}
              >
                {mini_bio}
              </Text>

            </Flex>

            {/* <Button
              width="100%"
              height="32px"
              colorScheme="messenger"
              bg="blue.500"
              borderRadius="4px"
              fontFamily="Poppins"
              fontWeight="600"
              onClick={handleButtonClick}
              disabled
              cursor="not-allowed"
            >
              Send Message
            </Button> */}

          </Box>
          )}
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default ProfileCard;
