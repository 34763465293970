/* eslint-disable consistent-return */
import React, {
  useContext, useState, useEffect, useCallback,
} from 'react';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  FormControl,
  FormLabel,
  Input,
  useTheme,
  Box,
  Textarea,
  Text,
  Flex,
  VStack,
  FormHelperText,
  Select,
} from '@chakra-ui/react';
import moment from 'moment-timezone';
import parse from 'html-react-parser';
import { TEEAuthDataContext } from '@texas-education-exchange/edx-portal-shared';
import {
  useCreateEventMutation,
  useUpdateEventByIdMutation,
} from '../../services/apis/eventsAPI';
import StatusAlert from '../ElementaryComponents/StatusAlert';
import { sanitizeData, removeHTMLAndSpaces } from '../../utils/helpers';
import MomentDatePicker from '../ElementaryComponents/MomentDatePicker';
import MomentTimePicker from '../ElementaryComponents/MomentTimePicker';
import TagsInput from '../ElementaryComponents/TagsInput';
import MaxCharExceedError from '../ElementaryComponents/MaxCharExceedError';

const CreateEventModal = ({
  buttonLabel,
  variant = 'solid',
  customBtnStyle = {},
  size = 'md',
  isEditing,
  eventData,
}) => {
  const theme = useTheme();
  const { isOpen, onOpen, onClose: onModalClose } = useDisclosure();
  const { auth } = useContext(TEEAuthDataContext);
  const today = moment();
  const [tags, setTags] = useState([]);
  const [eventTitle, setEventTitle] = useState('');
  // const [eventTitleLength, setEventTitleLength] = useState('');
  const [eventDescription, setEventDescription] = useState('');
  const [registrationLink, setRegistrationLink] = useState('');
  const [contactPerson, setContactPerson] = useState('');
  const [eventHost, setEventHost] = useState('');
  const [startTime, setStartTime] = useState(today);
  const [endTime, setEndTime] = useState(today);
  const [startDate, setStartDate] = useState(today);
  const [endDate, setEndDate] = useState(today);
  const [eventAddress, setEventAddress] = useState('');
  const [eventCity, setEventCity] = useState('');
  const [eventState, setEventState] = useState('');
  const [eventZip, setEventZip] = useState('');
  const [modalCloseTrigger, setModalCloseTrigger] = useState(false);
  const [isInvalidURL, setIsInvalidURL] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [locationType, setLocationType] = useState('');
  const allLocationsType = ['On site', 'Hybrid', 'Virtual'];

  const userTimezone = moment.tz.guess();
  const TZAbbreviated = moment.tz(userTimezone).zoneAbbr();

  const [
    createEvent,
    { isLoading, isError: isCreatingEventError, error: creatingEventError },
  ] = useCreateEventMutation();

  const validateForm = useCallback(() => {
    const selectedStartTime = startTime || eventData?.start_time;
    const selectedEndTime = endTime || eventData?.end_time;
    const selectedStartDate = startDate
      || (eventData?.start_date
        && moment(eventData?.start_date).format('MM/DD/YYYY'));
    const selectedEndDate = endDate
      || (eventData?.end_date && moment(eventData?.end_date).format('MM/DD/YYYY'));
    const allDateInputFilled = selectedStartDate
      && selectedStartTime
      && selectedEndDate
      && selectedEndTime;

    const combinedStartDate = moment(selectedStartDate)
      .hour(moment(selectedStartTime).hour())
      .minute(moment(selectedStartTime).minute())
      .second(moment(selectedStartTime).second())
      .toISOString();
    const combinedEndDate = moment(selectedEndDate)
      .hour(moment(selectedEndTime).hour())
      .minute(moment(selectedEndTime).minute())
      .second(moment(selectedEndTime).second())
      .toISOString();

    const momentStartDate = moment(combinedStartDate);
    const momentEndDate = moment(combinedEndDate);
    const isStartDateInFuture = momentStartDate.diff(moment(), 'minutes') > 0;
    const isEndDateAfterStartDate = momentEndDate.diff(momentStartDate, 'minutes') >= 15;

    const is_event_location_type_valid = () => {
      if (['On site', 'Hybrid'].includes(locationType)) {
        const valid_type = eventAddress?.length > 0
        && eventState?.length > 0
        && eventCity?.length > 0
        && eventZip?.length > 0;
        return valid_type;
      }
      return true;
    };

    if (
      removeHTMLAndSpaces(eventTitle)
      && removeHTMLAndSpaces(eventDescription)
      && removeHTMLAndSpaces(contactPerson)
      && eventTitle?.length <= 64
      && eventTitle?.length > 0
      && eventDescription?.length <= 560
      && eventDescription?.length > 0
      && contactPerson?.length <= 64
      && contactPerson?.length > 0
      && eventHost?.length <= 64
      && eventAddress?.length <= 64
      && eventState?.length <= 64
      && eventCity?.length <= 64
      && eventZip?.length <= 64
      && isStartDateInFuture
      && isEndDateAfterStartDate
      && allDateInputFilled
      && locationType
      && is_event_location_type_valid()
    ) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps, max-len
  }, [
    eventTitle,
    eventDescription,
    contactPerson,
    eventHost,
    eventAddress,
    eventCity,
    eventState,
    eventZip,
    startDate,
    startTime,
    endDate,
    endTime,
    locationType,
  ]);

  useEffect(() => {
    validateForm();
  }, [validateForm]);

  const [
    editEvent,
    {
      isLoading: isEditEventLoading,
      isError: isEditEventError,
      error: editEventError,
    },
  ] = useUpdateEventByIdMutation();

  const isValidURL = (str) => {
    const pattern = new RegExp(
      '^(http(s)?:\\/\\/)?'
        + '([-a-zA-Z0-9@:%._\\+~#=]{2,256}\\.)'
        + '[a-z]{2,6}\\b'
        + '([-a-zA-Z0-9@:%_\\+.~#?&//=]*)$',
      'i',
    );
    return pattern.test(str);
  };

  const modalUpdate = () => {
    setModalCloseTrigger((prev) => !prev);
  };

  const onClose = () => {
    modalUpdate();
    onModalClose();
  };

  useEffect(() => {
    if (isEditing && eventData) {
      setEventTitle(eventData?.title);
      setEventDescription(eventData?.description);
      setStartTime(moment(eventData?.start_date));
      setStartDate(moment(eventData?.start_date));
      setEndTime(moment(eventData?.end_date));
      setEndDate(moment(eventData?.end_date));
      setTags(eventData?.tags);
      setContactPerson(eventData?.contact_person);
      setEventHost(eventData?.event_host);
      setRegistrationLink(eventData?.registration_link);
      if (['On site', 'Hybrid'].includes(eventData?.type)) {
        setEventAddress(eventData?.location?.address);
        setEventCity(eventData?.location?.city);
        setEventState(eventData?.location?.state);
        setEventZip(eventData?.location?.zip_code);
      }
      setLocationType(eventData?.type);
    } else {
      setEventTitle('');
      setEventDescription('');
      setContactPerson('');
      setEventHost('');
      setRegistrationLink('');
      setStartDate(null);
      setStartTime(null);
      setEndDate(null);
      setEndTime(null);
      setTags([]);
      setEventAddress('');
      setEventCity('');
      setEventState('');
      setEventZip('');
      setLocationType('');
    }
    setIsInvalidURL(false);
  }, [eventData, isEditing, modalCloseTrigger]);

  const handleCreateEvent = async () => {
    const selectedStartTime = startTime || eventData.start_time;
    const selectedEndTime = endTime || eventData.end_time;
    const selectedStartDate = startDate || moment(eventData.start_date).format('MM/DD/YYYY');
    const selectedEndDate = endDate || moment(eventData.end_date).format('MM/DD/YYYY');

    const combinedStartDate = moment(selectedStartDate)
      .hour(moment(selectedStartTime).hour())
      .minute(moment(selectedStartTime).minute())
      .second(moment(selectedStartTime).second())
      .toISOString();
    const combinedEndDate = moment(selectedEndDate)
      .hour(moment(selectedEndTime).hour())
      .minute(moment(selectedEndTime).minute())
      .second(moment(selectedEndTime).second())
      .toISOString();

    if (registrationLink && !isValidURL(registrationLink)) {
      setIsInvalidURL(true);
        <StatusAlert
          status="error"
          alert_title="Invalid registration link. Link must include 'http://' or 'https://' and a valid domain."
        />;
        return;
    }
    setIsInvalidURL(false);

    const now = moment();
    if (moment(combinedStartDate).isBefore(now)) {
      <StatusAlert
        status="error"
        error="Event Start Time and Date must be in the future."
      />;
      return;
    }

    if (moment(combinedEndDate).isBefore(combinedStartDate)) {
      <StatusAlert
        status="error"
        error="Event End Time and Date must be after the start time and date."
      />;
      return;
    }

    try {
      const newEvent = {
        title: sanitizeData(eventTitle),
        description: sanitizeData(eventDescription),
        tags: sanitizeData(tags, 'arr_of_str'),
        registration_link: sanitizeData(registrationLink),
        contact_person: sanitizeData(contactPerson),
        event_host: sanitizeData(eventHost),
        start_date: combinedStartDate,
        end_date: combinedEndDate,
        type: locationType,
      };

      if (['On site', 'Hybrid'].includes(locationType)) {
        newEvent.location = {
          address: sanitizeData(eventAddress),
          city: sanitizeData(eventCity),
          state: sanitizeData(eventState),
          zip_code: sanitizeData(eventZip),
        };
      }

      if (!isEditing) {
        newEvent.author_id = sanitizeData(auth.user?.profile.sub);
      }
      if (isEditing) {
        await editEvent({ event_id: eventData._id, eventData: newEvent });
      } else {
        await createEvent(newEvent);
      }
      onClose();
    } catch (err) {
      console.log('Error creating or updating event:', err);
    }
  };
  return (
    <Box>
      <Button
        id="create-event"
        colorScheme="blue"
        bg={variant === 'solid' ? 'blue.500' : 'white'}
        variant={variant}
        onClick={onOpen}
        style={{
          ...theme.styles.global.button,
          fontWeight: '600',
          ...customBtnStyle,
        }}
        size={size}
      >
        {buttonLabel}
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} size="xl" isCentered>
        <ModalOverlay />
        <ModalContent borderTop="8px solid" borderColor="blue.600">
          <ModalHeader style={{ ...theme.styles.global.h3 }}>
            {isEditing ? 'Edit Event' : 'Create Event'}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <form>
              <FormControl id="group_modal" isRequired mb="1rem">
                <FormLabel
                  style={{
                    ...theme.styles.global.body,
                    fontSize: '14px',
                    margin: 0,
                    marginBottom: '4px',
                  }}
                >
                  Event Name
                </FormLabel>
                <Input
                  value={parse(eventTitle)}
                  onChange={(e) => setEventTitle(e.target.value)}
                />
                <MaxCharExceedError
                  message="Event name"
                  charCount={eventTitle?.length}
                />
              </FormControl>
              <FormControl id="description" mt={4} isRequired>
                <FormLabel
                  style={{
                    ...theme.styles.global.body,
                    fontSize: '14px',
                    margin: 0,
                    marginBottom: '4px',
                  }}
                >
                  Event Description
                </FormLabel>
                <Textarea
                  placeholder="Description"
                  resize="vertical"
                  minH="96px"
                  value={parse(eventDescription)}
                  onChange={(e) => setEventDescription(e.target.value)}
                />
                <MaxCharExceedError
                  message="Event description"
                  charCount={eventDescription?.length}
                  max_char_limit="560"
                />
              </FormControl>
              <Flex gap={4}>
                <FormControl
                  id="registration_link"
                  mt={4}
                  style={{
                    width: '200px',
                  }}
                  isRequired
                >
                  <FormLabel
                    style={{
                      ...theme.styles.global.body,
                      fontSize: '14px',
                      margin: 0,
                      marginBottom: '4px',
                    }}
                    isRequired
                  >
                    Location Type
                  </FormLabel>
                  <Select
                    height="40px"
                    placeholder="Select type "
                    aria-label="Select the location type for this event."
                    onChange={(e) => setLocationType(e.target.value)}
                    sx={{ padding: '0 12px' }}
                    value={locationType}
                  >
                    {allLocationsType.map((type) => {
                      return (
                        <option value={type} key={type}>
                          {type}
                        </option>
                      );
                    })}
                  </Select>
                </FormControl>
                <FormControl
                  id="registration_link"
                  mt={4}
                >
                  <FormLabel
                    style={{
                      ...theme.styles.global.body,
                      fontSize: '14px',
                      margin: 0,
                      marginBottom: '4px',
                    }}
                  >
                    Registration or Event Link
                  </FormLabel>
                  <Input
                    value={registrationLink}
                    placeholder="https://"
                    onChange={(e) => setRegistrationLink(e.target.value)}
                  />
                  <FormHelperText variant="details">
                    Please use either HTTP or HTTPS.
                  </FormHelperText>
                </FormControl>
              </Flex>
              {['On site', 'Hybrid'].includes(locationType) && (
                <>
                  <FormControl id="address" mb={2} isRequired>
                    <FormLabel
                      style={{
                        ...theme.styles.global.body,
                        fontSize: '14px',
                        margin: 0,
                        marginBottom: '4px',
                      }}
                    >
                      Address
                    </FormLabel>
                    <Input
                      value={eventAddress}
                      placeholder="The street address"
                      onChange={(e) => setEventAddress(e.target.value)}
                    />
                    <MaxCharExceedError
                      message="Address"
                      charCount={eventAddress?.length}
                    />
                  </FormControl>
                  <Flex direction="row">
                    <FormControl id="city" mr={4} w="50%" isRequired>
                      <FormLabel
                        style={{
                          ...theme.styles.global.body,
                          fontSize: '14px',
                          margin: 0,
                          marginBottom: '4px',
                        }}
                      >
                        City
                      </FormLabel>
                      <Input
                        value={parse(eventCity)}
                        onChange={(e) => setEventCity(e.target.value)}
                      />
                      <MaxCharExceedError
                        message="City"
                        charCount={eventCity?.length}
                      />
                    </FormControl>
                    <FormControl id="state" mr={4} w="20%" isRequired>
                      <FormLabel
                        style={{
                          ...theme.styles.global.body,
                          fontSize: '14px',
                          margin: 0,
                          marginBottom: '4px',
                        }}
                      >
                        State
                      </FormLabel>
                      <Input
                        value={parse(eventState)}
                        onChange={(e) => setEventState(e.target.value)}
                      />
                      <MaxCharExceedError
                        message="State"
                        charCount={eventState?.length}
                      />
                    </FormControl>
                    <FormControl id="zip_code" w="30%" isRequired>
                      <FormLabel
                        style={{
                          ...theme.styles.global.body,
                          fontSize: '14px',
                          margin: 0,
                          marginBottom: '4px',
                        }}
                      >
                        Zip Code
                      </FormLabel>
                      <Input
                        value={parse(eventZip)}
                        onChange={(e) => setEventZip(e.target.value)}
                      />
                      <MaxCharExceedError
                        message="Zip code"
                        charCount={eventZip?.length}
                      />
                    </FormControl>
                  </Flex>
                </>
              )}
              <FormControl id="contact_person" isRequired mt={4}>
                <FormLabel
                  style={{
                    ...theme.styles.global.body,
                    fontSize: '14px',
                    margin: 0,
                    marginBottom: '4px',
                  }}
                >
                  Contact Person
                </FormLabel>
                <Input
                  value={parse(contactPerson)}
                  placeholder="Enter a name, email address, or contact info"
                  onChange={(e) => setContactPerson(e.target.value)}
                />
                <MaxCharExceedError
                  message="Contact person name"
                  charCount={contactPerson?.length}
                />
              </FormControl>
              <FormControl id="event_host" mt={4}>
                <FormLabel
                  style={{
                    ...theme.styles.global.body,
                    fontSize: '14px',
                    margin: 0,
                    marginBottom: '4px',
                  }}
                >
                  Event Host/Sponsor
                </FormLabel>
                <Input
                  value={parse(eventHost)}
                  placeholder="May be an organization or person"
                  onChange={(e) => setEventHost(e.target.value)}
                />
                <MaxCharExceedError
                  message="Event host/sponsor "
                  charCount={eventHost?.length}
                />
              </FormControl>
              <Box mt={4}>
                <TagsInput tags={tags} setTags={setTags} label="Add Tags" />
              </Box>
              <FormControl id="start_time" isRequired mb="1rem" mt="1rem">
                <Flex
                  direction="row"
                  alignItems="flex-end"
                  justifyContent="space-between"
                  width="90%"
                >
                  <VStack spacing="-2" alignItems="start">
                    <FormLabel
                      style={{
                        ...theme.styles.global.body,
                        fontSize: '14px',
                        margin: 0,
                        marginBottom: '4px',
                      }}
                    >
                      Event Start Date
                    </FormLabel>
                    <MomentDatePicker
                      value={startDate}
                      minDate={today}
                      onChange={(date) => setStartDate(date)}
                    />
                  </VStack>

                  <Flex flexDirection="column" justifyContent="flex-end">
                    <Flex
                      justifyContent="center"
                      alignItems="center"
                      height="45px"
                      pr="1rem"
                    >
                      <Text
                        style={{
                          ...theme.styles.global.body,
                          fontSize: '14px',
                        }}
                      >
                        at
                      </Text>
                    </Flex>
                  </Flex>

                  <VStack spacing="-2" alignItems="start">
                    <FormLabel
                      style={{
                        ...theme.styles.global.body,
                        fontSize: '14px',
                        margin: 0,
                        marginBottom: '4px',
                      }}
                    >
                      Event Start Time (
                      {TZAbbreviated}
                      )
                    </FormLabel>
                    <MomentTimePicker
                      value={startTime}
                      minValue={
                        startDate && startDate.isSame(today, 'day')
                          ? today
                          : undefined
                      }
                      onChange={(time) => setStartTime(time)}
                    />
                  </VStack>
                </Flex>
              </FormControl>
              <FormControl id="end_time" isRequired mb="1rem">
                <Flex
                  direction="row"
                  alignItems="flex-end"
                  justifyContent="space-between"
                  width="90%"
                >
                  <VStack spacing="-2" alignItems="start">
                    <FormLabel
                      style={{
                        ...theme.styles.global.body,
                        fontSize: '14px',
                        margin: 0,
                        marginBottom: '4px',
                      }}
                    >
                      Event End Date
                    </FormLabel>
                    <MomentDatePicker
                      value={endDate}
                      minDate={startDate || today}
                      onChange={(date) => setEndDate(date)}
                    />
                  </VStack>
                  <Flex flexDirection="column" justifyContent="flex-end">
                    <Flex
                      justifyContent="center"
                      alignItems="center"
                      height="45px"
                      pr="1rem"
                    >
                      <Text
                        style={{
                          ...theme.styles.global.body,
                          fontSize: '14px',
                        }}
                      >
                        at
                      </Text>
                    </Flex>
                  </Flex>
                  <VStack spacing="-2" alignItems="start">
                    <FormLabel
                      style={{
                        ...theme.styles.global.body,
                        fontSize: '14px',
                        margin: 0,
                        marginBottom: '4px',
                      }}
                    >
                      Event End Time (
                      {TZAbbreviated}
                      )
                    </FormLabel>
                    <MomentTimePicker
                      value={endTime}
                      minValue={
                        startDate && endDate && startDate.isSame(endDate, 'day')
                          ? startTime?.clone().add(15, 'minutes')
                          : undefined
                      }
                      onChange={(time) => setEndTime(time)}
                    />
                  </VStack>
                </Flex>
              </FormControl>
            </form>
          </ModalBody>

          <ModalFooter alignSelf="flex-start">
            <Button
              mr={3}
              onClick={onClose}
              variant="outline"
              border="1px solid #CED8DD"
              _hover={{ opacity: 0.9 }}
              color="blue.600"
              style={{ ...theme.styles.global.body }}
            >
              Cancel
            </Button>
            <Button
              colorScheme="blue"
              bg="blue.500"
              style={{ ...theme.styles.global.body }}
              onClick={() => handleCreateEvent()}
              isDisabled={!isFormValid}
              _hover={{ opacity: !isFormValid ? 0.4 : 0.9 }}
            >
              Save
            </Button>
          </ModalFooter>
          {(isLoading || isEditEventLoading) && (
            <Box style={{ padding: '1rem 1.5rem' }}>
              <StatusAlert status="loading" alert_title="Creating Event..." />
            </Box>
          )}
          {isCreatingEventError && (
            <Box style={{ padding: '1rem 1.5rem' }}>
              <StatusAlert status="error" error={creatingEventError} />
            </Box>
          )}
          {isEditEventError && (
            <Box style={{ padding: '1rem 1.5rem' }}>
              <StatusAlert status="error" error={editEventError} />
            </Box>
          )}
          {isInvalidURL && (
            <Box style={{ padding: '1rem 1.5rem' }}>
              <StatusAlert
                status="error"
                alert_title="Invalid registration link. Link must include a valid domain."
              />
            </Box>
          )}
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default CreateEventModal;
