/* eslint-disable no-unused-vars */
import {
  Flex, Spacer, Text, useTheme, Button,
} from '@chakra-ui/react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import parse from 'html-react-parser';
import useAccessibleNavigation from '../../hooks/useAccessibleNavigation';
import { capitalizeFirstLetter, getFormattedExactTime, joinTruthyStrings } from '../../utils/helpers';
import DataUnavailable from '../ElementaryComponents/DataUnavailable';
import StatusAlert from '../ElementaryComponents/StatusAlert';
import FlaggedItemTitle from '../ElementaryComponents/FlaggedItemTitle';
import ProfileCardPopOver from '../PopOver/ProfileCardPopOver';
import UserAvatar from '../ElementaryComponents/UserAvatar';
import ModerationActions from '../ElementaryComponents/ModerationActions';
import AppealModal from '../Modals/AppealModal';

const customStyle = {
  boxShadow: 'none',
  border: 'none',
  background: 'none',
  fontSize: '16px',
  lineHeight: '1.5',
  fontWeight: 'bold',
  textAlign: 'center',
};

const AllReports = ({
  moderationReportsData,
  isModerationReportsFetching,
  isModerationReportsError,
  moderationReportsError,
  isInfractions = false,
  customNoDataMessage = 'No reports data available',
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const reportsData = moderationReportsData?.data;
  const accessibilityKeyHandler = useAccessibleNavigation();

  if (isModerationReportsFetching) return <StatusAlert status="loading" alert_title="Fetching reports..." />;
  if (isModerationReportsError) { return <StatusAlert status="error" error={moderationReportsError} />; }
  if (reportsData.length === 0) {
    return (
      <DataUnavailable
        display_message={[customNoDataMessage]}
        customStyle={customStyle}
      />
    );
  }

  return (
    <Flex flexDirection="column" width="full">
      {
        reportsData?.map((reportData) => {
          const {
            moderation,
            title,
            latest_report,
            latest_comment = {},
            _id: report_id,
            latest_moderation_action,
          } = reportData;

          const { reason, added_at, author_body = {} } = latest_report;
          const object_type = moderation?.object_type;
          const object_id = moderation?.object_id;
          const appealedReport = reason === 'appeal';

          const titlePrefix = `${object_type.toUpperCase()} REPORTED ${object_type === 'comment' ? '' : '-'} `;

          // TODO: implement when there is an author body of latest comment.
          const { comment_author_body = {} } = latest_comment;
          // console.log('Latest comment Author body', comment_author_body);

          const authorName = joinTruthyStrings({
            strings: [author_body.first_name, author_body.last_name],
            connectingString: ' ',
          });

          const navigatePath = `/reports/${report_id}`;
          const parsedTitle = object_type === 'comment' ? '' : title;
          const displayTitle = parsedTitle?.length > 50 ? `${parsedTitle.slice(0, 50)}...` : parsedTitle;

          return (
            <Flex
              className="postCard"
              flexDirection="column"
              gap={3}
              key={report_id}
              tabIndex={0}
              role={isInfractions ? 'button' : 'link'}
              onKeyDown={(event) => accessibilityKeyHandler(event, navigatePath, !isInfractions)}
              p="0.5rem 0"
              width="100%"
              mt={0}
              cursor={isInfractions ? 'default' : 'pointer'}
              onClick={() => (isInfractions ? '' : navigate(navigatePath))}
            >
              <Flex justifyContent="space-between">
                <Text style={{ ...theme.styles.global.h4, fontWeight: 700 }} className="forceFontStyleH4">
                  {titlePrefix}
                  {' '}
                  {' '}
                  {parse(displayTitle)}
                </Text>
                {
                 isInfractions
                  && (appealedReport
                    ? (
                      <Text>
                        Appealed at
                        {' '}
                        {getFormattedExactTime(added_at) }
                      </Text>
                    )
                    : (
                      <AppealModal
                        content_type={object_type}
                        content_id={object_id}
                        appealed_item={title}
                      />
                    ))
              }
              </Flex>
              {
                !isInfractions && (
                <Flex flexDir="col" justifyContent="space-between" ml={8} mb={2}>
                  <Flex>
                    <Text style={{ ...theme.styles.global.body }} mr={1}>
                      Requests:
                    </Text>
                    <Text
                      style={{ ...theme.styles.global.body, fontWeight: 'bold' }}
                      color="red.700"
                    >
                      {moderation?.report_count}
                    </Text>
                    <Text style={{ ...theme.styles.global.body }} mr={2} ml={6}>
                      Newest:
                    </Text>
                    {' '}
                    <Text style={{ ...theme.styles.global.body, fontWeight: 400 }}>
                      {authorName}
                      {' '}
                      at
                      {' '}
                      {getFormattedExactTime(added_at)}
                    </Text>
                  </Flex>
                  <Flex>
                    <Text style={{ ...theme.styles.global.body, fontWeight: 400 }}>
                      {capitalizeFirstLetter(reason)}
                    </Text>
                  </Flex>
                </Flex>
                )
              }
              {/* Moderation history */}
              <ModerationActions
                moderation={moderation}
                latest_moderation_action={latest_moderation_action}
                is_latest_moderation_action
              />
              <Flex />
            </Flex>
          );
        })
      }
    </Flex>
  );
};
export default AllReports;
