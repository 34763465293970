import React, { useContext, useEffect, useState } from 'react';
import { Flex } from '@chakra-ui/react';
import {
  SessionInactiveModal,
  LoadingScreen,
  useAuthAutoRefresh,
  useAuthActions,
  useLoadingState,
  useIdleSession,
  TEEAuthDataContext,
} from '@texas-education-exchange/edx-portal-shared';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useSearchParams } from 'react-router-dom';
import DefaultLayoutWrapper from './DefaultLayoutWrapper';
import { setLoggedInUser } from '../slices/authSlice';
import {
  useGetUserQuery,
  useLoginMutation,
  useLogoutMutation,
} from '../services/apis/usersAPI';
import SiteTour from './SiteTour';
import LoginUnauthorized from '../pages/LoginUnauthorized';

const LayoutWrapper = () => {
  useAuthAutoRefresh();
  const { auth, edxAppConfig } = useContext(TEEAuthDataContext);
  const { handleLogIn, handleLogOut } = useAuthActions();
  const dispatch = useDispatch();
  const { loadingState, stateMessage } = useLoadingState();
  const { showInactiveModal, onCloseInactiveModal } = useIdleSession({ timeout: 10000 * 60 });
  const [isClosingSession, setIsClosingSession] = useState(false);

  const [loginUser] = useLoginMutation();
  const [logoutUser] = useLogoutMutation();

  const location = useLocation();
  const currentLocation = `${location.pathname}${location.search}${location.hash}`;

  const [searchParams] = useSearchParams();
  const refreshTour = searchParams.get('refreshTour') ?? 1;

  const onLogin = async () => {
    await handleLogIn();
    await loginUser();
  };

  const onLogout = async () => {
    await handleLogOut();
    await logoutUser();
    dispatch(setLoggedInUser({}));
    setIsClosingSession(true);
  };

  const loggedInUser = useSelector((state) => state.auth.loggedInUser);
  // The only purpose of this call is to pass a me API call with a login header.
  const {
    error: loginError,
    isError: isLoginError,
  } = useGetUserQuery({
    login: loggedInUser?.profile?.sid !== auth?.user?.profile?.sid,
  }, {
    skip: !auth.isAuthenticated,
  });

  useEffect(() => {
    if (location.pathname !== '/callback') {
      localStorage.setItem('currentLocation', currentLocation);
    }
    if (auth.isAuthenticated) {
      dispatch(setLoggedInUser(auth.user));
    }
  }, [dispatch, auth, location.pathname, currentLocation]);

  return (
    <Flex id="LayoutWrapper">
      {!isLoginError && (
        <LoadingScreen state={stateMessage} delay={1} loading={loadingState !== 'finished'} />
      )}
      <Helmet>
        <title>
          The Exchange |
          {' '}
          {stateMessage}
        </title>
      </Helmet>
      {isLoginError && loginError.status === 401 ? (
        <LoginUnauthorized />
      ) : (
        <>
          <SessionInactiveModal
            show={showInactiveModal}
            isClosingSession={isClosingSession}
            onLogout={onLogout}
            onClose={onCloseInactiveModal}
          />
          <DefaultLayoutWrapper
            auth={auth}
            edxAppConfig={edxAppConfig}
            notificationBarMessage="The Exchange is now live!"
            isClosingSession={isClosingSession}
            onLogin={onLogin}
            onLogout={onLogout}
          />
          {/* The key is a hack to force Joyride to restart when the refresh in the query string
              changes. The refreshTour parameter will be a random number. -JJ
          */}
          <SiteTour key={refreshTour} />
        </>
      )}
    </Flex>
  );
};

export default LayoutWrapper;
