/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
import React, { useContext, useState } from 'react';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  useTheme,
  Box,
  Text,
  Flex,
} from '@chakra-ui/react';
import { TEEAuthDataContext } from '@texas-education-exchange/edx-portal-shared';
import parse from 'html-react-parser';
import { useParams } from 'react-router-dom';
// import { useSelector } from 'react-redux';
import QuillTextEditor from '../ElementaryComponents/QuillTextEditor';
import { useCreateCommentMutation, useUpdateCommentMutation } from '../../services/apis/commentsAPI';
import UserAvatar from '../ElementaryComponents/UserAvatar';
import ProfileCardPopOver from '../PopOver/ProfileCardPopOver';
import { sanitizeData, removeHTMLAndSpaces, joinTruthyStrings } from '../../utils/helpers';
import StatusAlert from '../ElementaryComponents/StatusAlert';

const ReplyModal = ({
  buttonLabel,
  variant = 'solid',
  data = {},
  customBtnStyle = {},
  comment_author = '',
  comment_quote = '',
  isEditingComment = false,
  commentData = {},
}) => {
  const theme = useTheme();
  const { group_id, post_id } = useParams();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { auth } = useContext(TEEAuthDataContext);
  const [commentTitle, setCommentTitle] = useState(isEditingComment ? commentData.title : '');
  const [commentTitleLength, setCommentTitleLength] = useState(0);
  const [createComment, { isError, Error }] = useCreateCommentMutation();
  const [
    updateComment,
    {
      isError: isCommentUpdateError,
      Error: commentUpdateError,
    },
  ] = useUpdateCommentMutation();
  const { author_body = {} } = data;
  const isButtonDisabled = !removeHTMLAndSpaces(commentTitle)
  || commentTitle.trim().length === 0 || commentTitleLength > 560;

  const handleReply = () => {
    const commentBodyObj = {
      author_id: auth.user.profile.sub,
      post_id,
      title: sanitizeData(commentTitle),
      quoted_user_id: [],
      quoted_comment_body: {},
      group_id,
    };

    if (comment_author) {
      const quoted_comment_body_obj = {
        author_name: comment_author,
        comment_title: data?.title,
        comment_id: data._id,
      };
      commentBodyObj.quoted_user_id = [data.author_id];
      commentBodyObj.quoted_comment_body = quoted_comment_body_obj;
    }
    createComment(commentBodyObj);
    setCommentTitle('');
    setCommentTitleLength(0);
    onClose();
  };

  const handleCancelReply = () => {
    setCommentTitle('');
    setCommentTitleLength(0);
    onClose();
  };

  const handleEditComment = () => {
    const { _id, quoted_user_id } = commentData;
    const editedComment = {
      title: sanitizeData(commentTitle),
    };
    updateComment({ comment_id: _id, body: editedComment }).unwrap();
  };

  let authorName = joinTruthyStrings({
    strings: [author_body.first_name, author_body.last_name],
    connectingString: ' ',
  });
  if (!authorName) authorName = author_body?._id;

  return (
    <>
      <Button
        onClick={onOpen}
        colorScheme="blue"
        bg={variant === 'solid' ? 'blue.500' : 'white'}
        variant={variant}
        style={{ ...theme.styles.global.button, fontWeight: '600', ...customBtnStyle }}
        size="sm"
      >
        {buttonLabel}
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} size="2xl" isCentered>
        <ModalOverlay />
        <ModalContent borderTop="8px solid" borderColor="blue.600" style={{ ...theme.styles.components.modalStyle }}>
          <ModalHeader style={{
            ...theme.styles.global.h3, display: 'flex', fontSize: '24px', alignItems: 'center',
          }}
          >
            <Flex alignItems="center" gap="0.5rem">
              <Text mr={4} fontWeight={700}>Replying to</Text>
              <ProfileCardPopOver
                memberProfile={author_body}
                userAvatar={(
                  <UserAvatar
                    name={authorName}
                    user_profile_pic=""
                  />
                )}
              />
              <Text style={{ ...theme.styles.global.body }}>
                {authorName}
              </Text>
            </Flex>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {
              comment_author
              && (
                <Box as="div" mb="1rem">
                  <Text fontWeight="700" mb={4}>
                    Quote:
                  </Text>
                  <Text
                    variant="italic"
                    backgroundColor="#EFF4F6"
                    p={2}
                    mr={0}
                    color="gray.600"
                    textAlign="left"
                  >
                    <Text as="span" fontWeight="bold" display="block">
                      {comment_author}
                      :
                    </Text>
                    <Text as="span" lineHeight="22px">
                      {parse(
                        comment_quote,
                      )}
                    </Text>
                  </Text>
                </Box>
              )

            }
            <QuillTextEditor
              quillText={commentTitle}
              setQuillText={setCommentTitle}
              customStyle={{ margin: 0 }}
              setQuillCharCount={setCommentTitleLength}
              quillCharCount={commentTitleLength}
            />
          </ModalBody>

          <ModalFooter justifyContent="flex-start" mt="2rem" width="100%">
            <Button
              className="change-focus"
              mr={3}
              onClick={handleCancelReply}
              variant="outline"
              border="1px solid #CED8DD"
              size="sm"
              _hover={{ opacity: 0.9 }}
              color="blue.600"
            >
              Cancel
            </Button>
            <Button
              colorScheme="blue"
              bg="blue.500"
              onClick={() => (isEditingComment ? handleEditComment() : handleReply())}
              size="sm"
              isDisabled={isButtonDisabled}
              _hover={{ opacity: isButtonDisabled ? 0.4 : 1 }}
            >
              Save
            </Button>
          </ModalFooter>
          {
            (isError || isCommentUpdateError) && (
              <Box style={{ padding: '1rem 1.5rem' }}>
                <StatusAlert status="error" error={Error || commentUpdateError} />
              </Box>
            )
          }
        </ModalContent>
      </Modal>
    </>
  );
};

export default ReplyModal;
