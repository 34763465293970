/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  useTheme,
  Text,
  Select,
  Flex,
  Spacer,
  Input,
  FormControl,
  FormLabel,
  FormHelperText,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react';
import ProfileCardPopOver from '../PopOver/ProfileCardPopOver';
import UserAvatar from '../ElementaryComponents/UserAvatar';
import { SearchIcon } from '../../icons';
import { joinTruthyStrings } from '../../utils/helpers';
import { useLazySearchUsersQuery } from '../../services/apis/searchAPI';
import { useModerateModeratorMutation } from '../../services/apis/moderationAPI';
import SearchedUsers from '../DiscussionGroups/SearchedUsers';

const ManageModeratorsModal = ({ group_id, moderatorsData }) => {
  const theme = useTheme();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [searchInputValue, setSearchInputValue] = useState('');
  const [moderateModerator] = useModerateModeratorMutation();
  const [showSearchedUsers, setShowSearchedUsers] = useState(false);

  const searchQueryParams = {
    page_number: 1,
    page_size: 100,
    sort_order: 'desc',
  };

  const [trigger, {
    data: searchedUserData,
    isFetching: isSearchedUserFetching,
    isError: isSearchUserError,
    error: searchUserError,
  }] = useLazySearchUsersQuery();

  useEffect(() => {
    setShowSearchedUsers(true);
  }, [searchedUserData]);

  const handleRemoveModerator = async (user_id) => {
    try {
      await moderateModerator({
        user_id,
        group_id,
        action: 'unassign',
      }).unwrap();
    } catch (error) {
      console.log('Error', error);
    }
  };

  const handleAddModerator = async (user_id) => {
    try {
      await moderateModerator({
        user_id,
        group_id,
        action: 'assign',
      }).unwrap();
    } catch (error) {
      console.log('Error', error);
    }
  };

  const handleSearchUser = () => {
    if (searchInputValue) {
      trigger({ ...searchQueryParams, query: searchInputValue });
    }
  };

  const onSearchKeyDown = (e) => {
    if (e?.key === 'Enter') {
      handleSearchUser();
    }
  };

  const handleOpenEdit = (e) => {
    onOpen(e);
  };

  const handleEditClose = (e) => {
    setSearchInputValue('');
    onClose(e);
  };

  return (
    <>
      <Button
        onClick={handleOpenEdit}
        height="40px"
        colorScheme="blue"
        variant="outline"
        mt={4}
        style={{
          ...theme.styles.global.button,
          fontWeight: '600',
          color: theme.colors.red[600],
          backgroundColor: 'white',
          border: `1px solid ${theme.colors.red[600]}`,
          width: '100%',
        }}
        size="sm"
      >
        Edit
      </Button>
      <Modal isOpen={isOpen} onClose={handleEditClose} size="2xl" isCentered>
        <ModalOverlay />
        <ModalContent
          borderTop="8px solid"
          borderColor="blue.600"
          style={{ ...theme.styles.components.modalStyle }}
        >
          <ModalHeader>
            <Text style={{ ...theme.styles.global.h3, fontSize: '24px' }}>
              Moderators for this Group
            </Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {/* Current Moderators list */}
            <Flex
              flexDir="column"
              maxHeight="250px"
              overflowY="scroll"
              pr="1.5rem"
            >
              {moderatorsData?.data?.map((moderatorData) => {
                const {
                  first_name, last_name, _id, email, job_title,
                } = moderatorData;

                const moderatorName = joinTruthyStrings({
                  strings: [first_name, last_name],
                  connectingString: ' ',
                });

                return (
                  <Flex key={_id} flexDirection="column" pt={2} pb={2}>
                    <Flex justifyContent="space-between" alignItems="center" gap={2}>
                      <ProfileCardPopOver
                        memberProfile={{}}
                        userAvatar={(
                          <UserAvatar
                            name={moderatorName}
                            user_profile_pic=""
                            avatarSize="XS"
                          />
                        )}
                      />
                      <Text style={{ ...theme.styles.global.h5, fontSize: '18px' }}>
                        {moderatorName}
                      </Text>
                      <Spacer />
                      <Text
                        style={{ ...theme.styles.global.body, fontSize: '14px' }}
                        color="blue.500"
                        cursor="pointer"
                        onClick={() => handleRemoveModerator(_id)}
                      >
                        Remove
                      </Text>
                    </Flex>
                    <Flex flexDirection="column" pl={8} mt={2} gap={1}>
                      <Text style={{ ...theme.styles.global.body2 }}>{job_title }</Text>
                      <Text style={{ ...theme.styles.global.body2 }}>{email}</Text>
                    </Flex>
                  </Flex>
                );
              })}
            </Flex>
            {/* Moderation Search */}
            <Flex pr="1.5rem">
              <FormControl>
                <FormLabel>
                  <Text style={{ ...theme.styles.global.h4 }} mt={8} mb={2}>
                    Add a New Moderator
                  </Text>
                </FormLabel>
                <InputGroup size="sm">
                  <Input
                    borderRadius="4px"
                    value={searchInputValue}
                    sx={{ padding: '0 12px' }}
                    placeholder="Search by name or email address"
                    isRequired
                    onChange={(e) => setSearchInputValue(e.target.value)}
                    onKeyDown={(e) => onSearchKeyDown(e)}
                  />
                  <InputRightElement color="white" bg="blue.500" borderRightRadius="4px" cursor="pointer">
                    <SearchIcon onClick={handleSearchUser} />
                  </InputRightElement>
                </InputGroup>
                <FormHelperText>
                  Use full names, partial matches may not be found.
                </FormHelperText>
              </FormControl>
            </Flex>
            {/* Searched Users list */}
            <Flex flexDir="column" mt={4} maxHeight="300px" overflow="scroll" pr="1.5rem">
              <SearchedUsers
                isSearchedUserFetching={isSearchedUserFetching}
                isSearchUserError={isSearchUserError}
                searchUserError={searchUserError}
                moderatorsData={moderatorsData}
                searchedUserData={searchedUserData}
                searchInputValue={searchInputValue}
                handleAddModerator={handleAddModerator}
                onClose={onClose}
                showSearchedUsers={showSearchedUsers}
                setShowSearchedUsers={setShowSearchedUsers}
              />
            </Flex>
          </ModalBody>
          <ModalFooter justifyContent="flex-start" width="100%">
            {/* {
            (isError || isCommentUpdateError) && (
              <Box style={{ padding: '1rem 1.5rem' }}>
                <StatusAlert status="error" error={Error || commentUpdateError} />
              </Box>
            )
          } */}
            <Button
              mr={3}
              onClick={handleEditClose}
              variant="outline"
              border="1px solid #CED8DD"
              size="sm"
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
export default ManageModeratorsModal;
