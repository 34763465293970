/* eslint-disable no-unused-vars */
import React, {
  useEffect, useState, useContext,
} from 'react';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  useTheme,
  Text,
  Flex,
  Tabs,
  Tab,
  TabList,
  TabPanels,
  TabPanel,
} from '@chakra-ui/react';
import { TEEAuthDataContext } from '@texas-education-exchange/edx-portal-shared';
import StatusAlert from '../ElementaryComponents/StatusAlert';
import {
  useGetAppOrderQuery,
  useSetAppOrderMutation,
  useFetchAllAppsQuery,
} from '../../services/apis/appsAPI';
import AppOrderCard from '../Cards/AppOrderCard';
import AppLists from '../Cards/AppLists';

const AppSettingsModal = () => {
  const theme = useTheme();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [orderType, setOrderType] = useState('user');

  const {
    isFetching: isFetchingAppOrder,
    refetch: refetchAppOrder,
    data: appOrder,
  } = useGetAppOrderQuery({ order_type: orderType });

  const {
    data: allAppsData,
    isFetching: isAllAppsFetching,
    isError: isAllAppsError,
    error: allAppsError,
  } = useFetchAllAppsQuery();

  const [currentAppOrder, setCurrentAppOrder] = useState([]);
  const [hiddenAppIdList, setHiddenAppIdList] = useState([]);
  const [selectedTab, setSelectedTab] = useState(0);

  const [setAppOrder] = useSetAppOrderMutation();

  const { auth } = useContext(TEEAuthDataContext);
  const currentUserRoleList = auth?.user?.profile?.role;
  const isExchangeAdmin = currentUserRoleList?.includes(
    'Community.ExchangeAdmin',
  );

  useEffect(() => {
    if (appOrder?.data?.length > 0) {
      const appOrderData = appOrder.data;
      const hiddenAppIds = [];
      const appsToDisplay = [];

      appOrderData.forEach((app) => {
        if (app?.title === 'Community' || !app?.show_in_app_launcher) {
          hiddenAppIds.push(app?._id);
        } else {
          appsToDisplay.push(app);
        }
      });

      setHiddenAppIdList(hiddenAppIds);
      setCurrentAppOrder(appsToDisplay);
    }
  }, [appOrder?.data, selectedTab, isOpen]);

  useEffect(() => {
    setSelectedTab(0);
    setOrderType('user');
    refetchAppOrder();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const currentAppOrderIds = currentAppOrder.map((item) => {
    return item?._id;
  });

  const currentPinnedApps = currentAppOrder.map((ordered_app, index) => {
    return allAppsData?.data?.[0]?.pinned_apps?.find((a) => a._id === ordered_app?._id);
  }).filter((a) => a);

  const currentOtherApps = currentAppOrder.map((ordered_app, index) => {
    return allAppsData?.data?.[0]?.your_other_apps?.find((a) => a._id === ordered_app?._id);
  }).filter((a) => a);

  const handleTabChange = () => {
    if (selectedTab === 0) {
      setSelectedTab(1);
      setOrderType('default');
    } else {
      setSelectedTab(0);
      setOrderType('user');
    }
  };

  const handleSave = async () => {
    try {
      await setAppOrder({
        order_type: orderType,
        body: { app_order: [...currentAppOrderIds, ...hiddenAppIdList] },
      }).unwrap();
    } catch (error) {
      console.log('Error', error);
    }
    onClose();
  };

  // const handleOpen = () => {
  //   refetchAppOrder();
  //   isOpen();
  // };

  return (
    <>
      <Button
        onClick={onOpen}
        style={{
          backgroundColor: theme.colors.blue[500],
          color: 'white',
          border: `1px solid ${theme.colors.blue[500]}`,
          padding: '0px 12px',
        }}
        size="sm"
      >
        App Display Settings
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} size="3xl" isCentered>
        <ModalOverlay />
        <ModalContent
          borderTop="8px solid"
          borderColor="blue.600"
          style={{ ...theme.styles.components.modalStyle }}
        >
          <ModalHeader>
            <Text variant="h2" style={{ ...theme.styles.global.h3, fontSize: '24px' }}>
              Apps Settings
            </Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Tabs
              onChange={handleTabChange}
              index={selectedTab}
              mb={6}
              variant="enclosed"
            >
              {isExchangeAdmin && (
              <TabList>
                <Tab
                  aria-label="My Apps tab"
                  _selected={{
                    fontWeight: 'bold',
                    color: 'blue.600',
                    borderBottom: '2px solid',
                  }}
                  position="relative"
                >
                  My Apps
                </Tab>
                <Tab
                  aria-label="Community Default tab"
                  _selected={{
                    fontWeight: 'bold',
                    color: 'blue.600',
                    borderBottom: '2px solid',
                  }}
                  position="relative"
                >
                  Community Default
                </Tab>
              </TabList>
              )}
              <TabPanels>
                {['user', 'default'].map((tab) => {
                  return (
                    <TabPanel key={tab}>
                      <AppLists
                        isFetchingAppOrder={isFetchingAppOrder}
                        orderType={tab}
                        currentAppOrder={currentAppOrder}
                        setCurrentAppOrder={setCurrentAppOrder}
                        currentPinnedApps={currentPinnedApps}
                        currentOtherApps={currentOtherApps}
                      />
                    </TabPanel>
                  );
                })}
              </TabPanels>
            </Tabs>
          </ModalBody>
          <ModalFooter justifyContent="flex-start" width="100%">
            <Button
              mr={3}
              onClick={onClose}
              variant="outline"
              border="1px solid #CED8DD"
              size="sm"
              width="100px"
            >
              Cancel
            </Button>
            <Button
              mr={3}
              onClick={handleSave}
              variant="outline"
              border="1px solid #CED8DD"
              size="sm"
              width="100px"
              style={{
                backgroundColor: theme.colors.blue[500],
                color: 'white',
                border: `1px solid ${theme.colors.blue[500]}`,
                padding: '0px 12px',
              }}
            >
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
export default AppSettingsModal;
