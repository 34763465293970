import React, { useState } from 'react';
import { Grid, GridItem, ChakraProvider } from '@chakra-ui/react';
import { Footer } from '@texas-education-exchange/edx-portal-shared';
import { useLocation } from 'react-router-dom';
import LeftSideBar from './LeftSideBar';
import AppRouter from '../routes/AppRouter';
import { useGetUserQuery } from '../services/apis/usersAPI';
import chakra_ui_theme from '../styles/chakra_ui_theme';
import LoadingModal from '../components/ElementaryComponents/LoadingModal';

const RootLayout = () => {
  const [navExpanded, setNavExpanded] = useState(false);
  const location = useLocation();
  const {
    data: userData,
    isLoading: isUserDataLoading,
  } = useGetUserQuery();
  const notFoundPage = location.pathname === '/not-found';

  return (
    <ChakraProvider theme={chakra_ui_theme}>
      <LoadingModal isOpen={isUserDataLoading} />
      <Grid
        id="RootLayout"
        templateAreas={`
                    "nav main"
                    "footer footer"`}
        gridTemplateRows="1fr 5.25rem"
        gridTemplateColumns={`${navExpanded ? '9.5rem' : '3rem'} 1fr`}
        mt={0}
      >

        {/* Navigation (LeftSideBar) */}
        <GridItem area="nav" height="100%">
          <LeftSideBar
            navExpanded={navExpanded}
            setNavExpanded={setNavExpanded}
            access_sidebar={userData?.access_sidebar}
            userData={userData}
          />
        </GridItem>

        {/* Main Content */}
        <GridItem
          area="main"
          p={notFoundPage ? '0rem' : '3rem'}
          pt={notFoundPage ? '0' : '1rem'}
        >
          <AppRouter />
        </GridItem>

        {/* Footer */}
        <GridItem area="footer">
          <Footer />
        </GridItem>
      </Grid>
    </ChakraProvider>
  );
};

export default RootLayout;
