import React, { useEffect, useState } from 'react';
import { Flex, Square, IconButton } from '@chakra-ui/react';
import {
  DashboardIcon,
  GroupsIcon,
  HelpIcon,
  TrainingIcon,
  ArrowBackIcon,
  ArrowForwardIcon,
  SearchIcon,
  MoreHorizIcon,
  EventsIcon,
  ReportsIcon,
  AssessmentIcon,
  WarningIcon,
  NotificationIcon,
} from '../icons/index';
import LeftSideBarIcon from '../components/NavMenu/LeftSideBarIcon';
import CustomBadge from '../components/ElementaryComponents/CustomBadge';

const LeftSidebar = ({
  navExpanded,
  setNavExpanded,
  access_sidebar = {},
  userData = {},
}) => {
  const iconStyle = {
    fontSize: '1rem',
  };
  const latest_guidelines_status = userData?.data?.latest_guidelines_status;
  const guidelines_accepted = latest_guidelines_status === 'accepted';
  const sidebar_without_guidelines = ['Dashboard', 'Events', 'Trainings', 'Help', 'Extras'];
  const [showGuidelinesModal, setShowGuidelinesModal] = useState(
    !guidelines_accepted,
  );

  useEffect(() => {
    if (!showGuidelinesModal && !guidelines_accepted) {
      setShowGuidelinesModal(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [guidelines_accepted]);

  // Expand/collapse button style
  const expandCollapseWrapper = {
    backgroundColor: 'white',
    height: '3rem',
    width: '2rem',
    position: 'absolute',
    bottom: '3rem',
    right: '-2.5rem',
    borderRadius: '0 4px 4px 0',
    cursor: 'pointer',
  };

  const leftNavOptions = [
    {
      menu_item: 'Dashboard',
      path_name: 'dashboard',
      menu_icon: (
        <DashboardIcon aria-label="Dashboard" style={{ ...iconStyle }} />
      ),
    },
    {
      menu_item: 'Forum',
      path_name: 'forum',
      menu_icon: <GroupsIcon aria-label="Forum" style={{ ...iconStyle }} />,
    },
    {
      menu_item: 'Trainings',
      path_name: 'training',
      menu_icon: (
        <TrainingIcon aria-label="Trainings" style={{ ...iconStyle }} />
      ),
    },
    {
      menu_item: 'Events',
      path_name: 'events',
      menu_icon: <EventsIcon aria-label="Events" style={{ ...iconStyle }} />,
    },
    {
      menu_item: 'Search',
      path_name: 'search',
      menu_icon: <SearchIcon aria-label="Search" style={{ ...iconStyle }} />,
    },
    {
      menu_item: 'Help',
      path_name: 'help#community',
      menu_icon: <HelpIcon aria-label="Help" style={{ ...iconStyle }} />,
    },
    {
      menu_item: 'Moderation',
      path_name: 'reports',
      menu_icon: (
        <div style={{ position: 'relative' }}>
          <ReportsIcon aria-label="Reports" style={{ ...iconStyle }} />
          <CustomBadge
            itemCount={access_sidebar?.pending_moderation_report}
            aria-label={`${access_sidebar?.pending_moderation_report} new reports`}
            style={{
              top: '-6px',
              right: '-10px',
              variant: 'subtle',
            }}
          />
        </div>
      ),
    },
    // {
    //   menu_item: 'Messages',
    //   path_name: 'messages',
    //   menu_icon: <MessagesIcon style={{ ...iconStyle }} />,
    // },
    {
      menu_item: 'Analytics',
      path_name: 'analytics',
      menu_icon: <AssessmentIcon style={{ ...iconStyle }} />,
    },
    {
      menu_item: 'Infractions',
      path_name: 'infractions',
      menu_icon: <WarningIcon style={{ ...iconStyle }} />,
    },
    {
      menu_item: 'Notification Center',
      path_name: 'notification-center',
      menu_icon: <NotificationIcon style={{ ...iconStyle }} />,
    },
    {
      menu_item: 'Extras',
      menu_icon: <MoreHorizIcon style={{ ...iconStyle }} />,
      sub_items: [
        {
          menu_item: 'Site Tour',
          path_name: `/dashboard?startSiteTour=true&refreshTour=${Math.floor(
            Math.random() * 1000,
          )}`,
        },
        {
          menu_item: 'Community Guidelines',
          path_name: '',
        },
        {
          menu_item: 'Subscriptions',
          path_name: 'subscriptions',
        },
      ],
    },
  ];

  return (
    <Flex
      as="nav"
      alignItems="center"
      id="LeftNavBar"
      bg="blue.600"
      flex={1}
      flexDirection="column"
      height="100%"
      minHeight="84vh"
      position="relative"
      padding={navExpanded ? '0.5rem 0.5rem' : '0.5rem 0'}
      cursor="pointer"
      onClick={() => setNavExpanded(!navExpanded)}
    >
      {/* Menu items */}
      <Flex flexDirection="column">
        {leftNavOptions?.map((navObj) => {
          if (!access_sidebar?.moderation && navObj.path_name === 'reports') {
            return null;
          }
          if (!access_sidebar?.report && navObj.path_name === 'analytics') {
            return null;
          }

          if (!access_sidebar?.notification && navObj.path_name === 'notification-center') {
            return null;
          }

          if (!guidelines_accepted && !sidebar_without_guidelines.includes(navObj.menu_item)) {
            return null;
          }

          if (navObj.menu_item === 'Extras' && !guidelines_accepted) {
            const indexToRemove = navObj.sub_items.findIndex(
              (item) => item.menu_item === 'Site Tour',
            );
            if (indexToRemove !== -1) {
              navObj.sub_items.splice(indexToRemove, 1);
            }
          }

          return (
            <LeftSideBarIcon
              {...navObj}
              navExpanded={navExpanded}
              setNavExpanded={setNavExpanded}
              key={navObj.menu_item}
              guidelines_accepted={guidelines_accepted}
            />
          );
        })}
      </Flex>

      {/* Expand/Collapse button */}
      <IconButton
        aria-label={navExpanded ? 'Show only the icons and main pages in the navigation menu.' : 'Show the full text and sub-pages in the navigation menu.'}
        style={{ ...expandCollapseWrapper, zIndex: 10 }}
        icon={(
          <Square
            onClick={() => setNavExpanded(!navExpanded)}
          >
            {navExpanded ? <ArrowBackIcon /> : <ArrowForwardIcon />}
          </Square>
        )}
      />
    </Flex>
  );
};

export default LeftSidebar;
