/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import { HelmetProvider } from 'react-helmet-async';
// import { ThemeProvider, createTheme } from '@mui/material/styles';
import 'react-datepicker/dist/react-datepicker.css';
import {
  LoadingScreen, TEEAuthContextProvider, baseTheme,
} from '@texas-education-exchange/edx-portal-shared';
import LayoutWrapper from './Layout/LayoutWrapper';

const App = ({ appConfig }) => {
  // const muiTheme = createTheme();
  return (
    <div id="App">
      {/* <ColorModeScript initialColorMode={baseTheme.config.initialColorMode} /> */}
      <ChakraProvider theme={baseTheme}>
        <HelmetProvider>
          <LoadingScreen loading={!appConfig} state="loading..." delay={0.5} />
          {appConfig
            && (
            <TEEAuthContextProvider edxAppConfig={appConfig}>
              <LayoutWrapper />
            </TEEAuthContextProvider>
            )}
        </HelmetProvider>
      </ChakraProvider>
      {/* </ThemeProvider> */}
    </div>
  );
};

export default App;
