import React, { useEffect, useState } from 'react';
import {
  Box, Flex, useTheme, Grid,
} from '@chakra-ui/react';
import { useLocation, useParams, NavLink } from 'react-router-dom';
import Updates from '../components/Dashboard/Updates';
import Course from '../components/MyTraining/Course';
import Courses from '../components/MyTraining/Courses';
import SectionContainer from '../components/SectionContainer';
import CreateCourseModal from '../components/Modals/CreateCourseModal';
import SectionFooter from '../components/NavMenu/SectionFooter';

import { useFetchAllCoursesQuery, useFetchCourseUpdatesQuery } from '../services/apis/coursesAPI';
import StatusAlert from '../components/ElementaryComponents/StatusAlert';

const Training = ({
  showNumberSelect = true,
  guidelines_accepted,
}) => {
  const theme = useTheme();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const app_id = queryParams.get('app_id');
  const { course_id } = useParams();

  const [myTrainingSortOrder, setMyTrainingSortOrder] = useState('desc');
  const [allTrainingSortOrder, setAllTrainingSortOrder] = useState('desc');
  const [currentPageAllTraining, setCurrentPageAllTraining] = useState(1);
  const [currentPageMyTraining, setCurrentPageMyTraining] = useState(1);
  const [mySearchQueryObj, setMySearchQueryObj] = useState({
    query: '',
    last_created: null,
  });
  const [allSearchQueryObj, setAllSearchQueryObj] = useState({
    query: '',
    last_created: null,
  });

  useEffect(() => {
    setMySearchQueryObj({
      query: '',
      last_created: null,
    });
    setAllSearchQueryObj({
      query: '',
      last_created: null,
    });
  }, [location.pathname]);

  const [selectedShowNumberMyTraining, setSelectedShowNumberMyTraining] = useState('5');
  const [selectedShowNumberAllTraining, setSelectedShowNumberAllTraining] = useState('5');

  const [currentPageUpdates, setCurrentPageUpdates] = useState(1);
  const [selectedShowNumberUpdates, setSelectedShowNumberUpdates] = useState(5);

  // Rtk Query
  // Query Objects
  const myTrainingQueryObj = {
    page_number: currentPageMyTraining,
    page_size: selectedShowNumberMyTraining,
    sort_order: myTrainingSortOrder,
    sort_by: 'created_at',
    // no_app_id: true,
    pin: true,
    query: mySearchQueryObj.query,
    last_created: mySearchQueryObj.last_created,
    ...(app_id ? {} : { include_apps: true }),
  };

  const allTrainingQueryObj = {
    page_number: currentPageAllTraining,
    page_size: selectedShowNumberAllTraining,
    sort_order: allTrainingSortOrder,
    sort_by: 'created_at',
    // no_app_id: true,
    pin: false,
    query: allSearchQueryObj.query,
    last_created: allSearchQueryObj.last_created,
    ...(app_id ? {} : { include_apps: true }),
  };

  if (app_id) {
    myTrainingQueryObj.app_id = app_id;
    allTrainingQueryObj.app_id = app_id;
  }

  const updatesQueryObj = {
    page_number: currentPageUpdates,
    page_size: selectedShowNumberUpdates,
  };

  const {
    data: myTrainingData,
    isFetching: isMyTrainingFetching,
    isError: isMyTrainingError,
    error: myTrainingError,
    refetch: refetchMyCourse,
  } = useFetchAllCoursesQuery(myTrainingQueryObj);

  const {
    data: allTrainingData,
    isFetching: isAllTrainingFetching,
    isError: isAllTrainingError,
    error: allTrainingError,
    refetch: refetchAllCourse,
  } = useFetchAllCoursesQuery(allTrainingQueryObj);

  const {
    data: allCoursesUpdatesData,
    isFetching: isAllCoursesUpdatesFetching,
    isError: isAllCoursesUpdatesError,
    error: allCoursesUpdatesError,
    refetch: refetchCourseUpdates,
  } = useFetchCourseUpdatesQuery(updatesQueryObj);

  const isTrainingPath = location.pathname === '/training';

  useEffect(() => {
    refetchCourseUpdates();
    refetchAllCourse();
    refetchMyCourse();
  }, [isTrainingPath, refetchCourseUpdates, refetchAllCourse, refetchMyCourse]);

  const can_create = allTrainingData?.permissions?.course?.can_create;

  // render myTraining Component
  const myTrainingComponent = () => {
    if (isMyTrainingFetching) return <StatusAlert status="loading" alert_title="Fetching Pinned Trainings Data..." />;
    if (isMyTrainingError) return <StatusAlert status="error" error={myTrainingError} />;
    const coursesData = myTrainingData.data;
    return (
      <>
        <Courses
          coursesData={coursesData}
          is_pinned
          selectedShowNumber={selectedShowNumberMyTraining}
          noDataMessage={[mySearchQueryObj?.query ? 'Your search did not find any pinned trainings' : 'Pin a training to see the latest updates from it and always have it at the top of this page.']}
        />
        <SectionFooter
          showNumberSelect={showNumberSelect}
          selectedShowNumber={selectedShowNumberMyTraining}
          setSelectedShowNumber={setSelectedShowNumberMyTraining}
          currentPage={currentPageMyTraining}
          setCurrentPage={setCurrentPageMyTraining}
          totalCount={myTrainingData.meta?.total_pages}
        />
      </>
    );
  };

  // render allTraining Component
  const allTrainingComponent = () => {
    if (isAllTrainingFetching) return <StatusAlert status="loading" alert_title="Fetching All Training Data..." />;
    if (isAllTrainingError) return <StatusAlert status="error" error={allTrainingError} />;
    return (
      <>
        <Courses
          coursesData={allTrainingData.data}
          is_pinned
          selectedShowNumber={selectedShowNumberAllTraining}
          noDataMessage={[allSearchQueryObj?.query ? 'Your search did not find any trainings' : 'There is no training available for this section.']}
        />
        <SectionFooter
          showNumberSelect={showNumberSelect}
          selectedShowNumber={selectedShowNumberAllTraining}
          setSelectedShowNumber={setSelectedShowNumberAllTraining}
          currentPage={currentPageAllTraining}
          setCurrentPage={setCurrentPageAllTraining}
          totalCount={allTrainingData.meta.total_pages}
        />
      </>
    );
  };

  if (course_id) {
    return <Course guidelines_accepted={guidelines_accepted} />;
  }
  return (
    <Box>
      <Flex
        justifyContent="space-between"
        alignItems="center"
        py={4}
      >
        <NavLink
          to="/training"
          style={{
            ...theme.styles.global.h1,
            color: `${theme.colors.blue[800]}`,
            display: 'inline-block',
          }}
        >
          Exchange Trainings
        </NavLink>
        {
          isTrainingPath && can_create && guidelines_accepted
          && (
          <CreateCourseModal
            buttonLabel="Create Training"
            title="My Modal"
          />
          )
        }
      </Flex>

      <Grid
        templateAreas={`"myTraining trainingUpdates"
                      "allTraining trainingUpdates"`}
        templateColumns="2.6fr 1fr"
        templateRows="auto 1fr"
        gap="2rem"
        pt={4}
      >
        {/* My Training */}
        {
          isTrainingPath
          && (
          <Box
            id="MyTraining"
            gridArea="myTraining"
            pt={0}
            pl={0}
          >
            <SectionContainer
              sectionTitle="Pinned Trainings"
              selectedShowNumber={selectedShowNumberMyTraining}
              setSelectedShowNumber={setSelectedShowNumberMyTraining}
              setSortOrder={setMyTrainingSortOrder}
              childComponent={(myTrainingComponent())}
              searchQueryObj={mySearchQueryObj}
              setSearchQueryObj={setMySearchQueryObj}
              searchIcon
            />
          </Box>
          )
        }

        {/* Updates Section */}
        <Box gridArea="trainingUpdates" pt={0}>
          <Updates
            sectionTitle="Training Updates"
            seeAllLink={false}
            currentPage={currentPageUpdates}
            setCurrentPage={setCurrentPageUpdates}
            updatesData={allCoursesUpdatesData}
            isFetchingUpdates={isAllCoursesUpdatesFetching}
            isFetchingUpdatesError={isAllCoursesUpdatesError}
            fetchingUpdatesError={allCoursesUpdatesError}
            selectedShowNumber={selectedShowNumberUpdates}
            setSelectedShowNumber={setSelectedShowNumberUpdates}
            guidelines_accepted={guidelines_accepted}
            noDataMessage={['Pin a training to see the updates here.']}
          />
        </Box>
        {/* All Training */}
        {
        isTrainingPath
        && (
          <Box gridArea="allTraining" pt={0}>
            <SectionContainer
              sectionTitle="All Trainings"
              sectionInfoData={allTrainingData}
              setSortOrder={setAllTrainingSortOrder}
              childComponent={(allTrainingComponent())}
              searchQueryObj={allSearchQueryObj}
              setSearchQueryObj={setAllSearchQueryObj}
              searchIcon
            />
          </Box>
        )
        }
      </Grid>
    </Box>
  );
};

export default Training;
