/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import {
  Text,
  useTheme,
  Flex,
  Box,
  Popover,
  PopoverBody,
  PopoverContent,
  useDisclosure,
  IconButton,
} from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';
import pluralize from 'pluralize';
import {
  OpenInNewIcon,
  HelpIcon,
  GroupsIcon,
  CollectionsBookmarkIcon,
  MoreVertIcon,
} from '../../icons/index';
import TogglePinIcon from '../ElementaryComponents/TogglePinIcon';
import CustomPopoverTrigger from '../ElementaryComponents/CustomPopoverTrigger';
import ExternalLink from '../ElementaryComponents/ExternalLink';
import { useFetchAllPostsQuery } from '../../services/apis/postsAPI';
import StatusAlert from '../ElementaryComponents/StatusAlert';
import { useFetchAllCoursesQuery } from '../../services/apis/coursesAPI';
import SeeAllNavLink from '../NavMenu/SeeAllNavLink';
import DataUnavailable from '../ElementaryComponents/DataUnavailable';
import { stringToID } from '../../utils/helpers';

const AppLauncherCard = ({
  appDetailsObj,
  is_pinned,
  my_apps,
  guidelines_accepted,
}) => {
  const theme = useTheme();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [showTopDiscussions, setShowTopDiscussions] = useState(false);
  const [showExchangeTraining, setShowExchangeTraining] = useState(false);

  const {
    title: app_title,
    description = '',
    marketing_website = '',
    documentation = '',
    pin_id = '',
    _id,
    group_id = null,
    product_catalog,
  } = appDetailsObj;

  const otherAppStyles = {};
  const iconClosedcolor = my_apps ? '#fff' : '#efefef';
  if (!my_apps) otherAppStyles.backgroundColor = '#efefef';

  const createPinBodyObj = {
    child_object_id: _id,
    child_object_type: 'app',
  };

  const myPostsQueryObj = {
    group_id,
    page_number: 1,
    sort_by: 'likes',
    page_size: 3,
    sort_order: 'desc',
  };

  const myCoursesQueryObj = {
    app_id: _id,
    page_number: 1,
    sort_by: 'likes',
    page_size: 3,
    sort_order: 'desc',
  };

  const {
    data: topDiscussionsData,
    isFetching: isFetchingDiscussionsData,
    isError: isDiscussionsDataError,
    error: discussionsDataError,
  } = useFetchAllPostsQuery(myPostsQueryObj, { skip: !group_id });

  const {
    data: topCoursesData,
    isFetching: isFetchingCoursesData,
    isSuccess: isCourseDataSuccess,
    isError: isCoursesDataError,
    error: coursesDataError,
  } = useFetchAllCoursesQuery(myCoursesQueryObj, { skip: !_id });

  const isTrainingAvailable = isCourseDataSuccess
    && Array.isArray(topCoursesData?.data)
    && topCoursesData.data.length;

  const getTopDiscussionsData = () => {
    if (isFetchingDiscussionsData) {
      return (
        <StatusAlert status="loading" alert_title="Fetching Top Discussions" />
      );
    }
    if (isDiscussionsDataError) return <StatusAlert status="error" error={discussionsDataError} />;
    if (topDiscussionsData?.data.length !== 0) {
      return (
        <>
          <Box>
            {topDiscussionsData?.data.map((top_discussion) => {
              const { title, like_count, _id: post_id } = top_discussion;
              return (
                <Box mb="0.25rem" key={post_id}>
                  <NavLink
                    mt="8px"
                    key={title}
                    to={`/forum/${group_id}/${post_id}`}
                  >
                    <Text
                      style={{ ...theme.styles.global.body2 }}
                      color="blue.600"
                      lineHeight="10px"
                    >
                      {title}
                    </Text>
                  </NavLink>

                  <Text fontStyle="normal" color="#6D7B85" lineHeight="16px">
                    {pluralize('like', like_count, true)}
                  </Text>
                </Box>
              );
            })}
          </Box>
          <Flex justifyContent="flex-end">
            <SeeAllNavLink
              navigate_to={`/forum/${group_id}`}
              display_message="See More"
            />
          </Flex>
        </>
      );
    }
    return (
      <DataUnavailable
        display_message={[
          'There are no discussions for this app yet.',
          {
            text: 'Create a Post',
            path: `/forum/${group_id}`,
          },
          'to start one.',
        ]}
        customStyle={{
          boxShadow: 'none',
          border: 'none',
          background: 'none',
          fontSize: '12px',
          lineHeight: '1.5',
        }}
      />
    );
  };

  const getCoursesData = () => {
    if (isFetchingCoursesData) {
      return (
        <StatusAlert
          status="loading"
          alert_title="Fetching Exchange Trainings Data"
        />
      );
    }
    if (isCoursesDataError) return <StatusAlert status="error" error={coursesDataError} />;
    if (isTrainingAvailable) {
      return (
        <>
          <Box>
            {topCoursesData?.data.map((exchange_training) => {
              const { title, like_count, _id: course_id } = exchange_training;
              return (
                <Box
                  mt="8px"
                  style={{ ...theme.styles.global.body2 }}
                  key={title}
                >
                  <NavLink mt="8px" key={title} to={`/training/${course_id}`}>
                    <Text
                      style={{ ...theme.styles.global.body2 }}
                      color="blue.600"
                      lineHeight="10px"
                    >
                      {title}
                    </Text>
                  </NavLink>
                  <Flex>
                    <Text fontStyle="normal" color="#6D7B85" lineHeight="16px">
                      {pluralize('like', like_count, true)}
                    </Text>
                  </Flex>
                </Box>
              );
            })}
          </Box>
          <Flex justifyContent="flex-end">
            <SeeAllNavLink
              navigate_to={`/training?app_id=${_id}`}
              display_message="See More"
            />
          </Flex>
        </>
      );
    }
    return (
      <DataUnavailable
        display_message={['Training is not yet available for this app.']}
        customStyle={{
          boxShadow: 'none',
          border: 'none',
          background: 'none',
          fontSize: '12px',
          lineHeight: '1.5',
        }}
      />
    );
  };

  const onKeyDownInTrigger = (e) => {
    if (e?.key === 'Enter') {
      onOpen();
    }
  };

  const onClickDiscussions = () => {
    setShowTopDiscussions(!showTopDiscussions);
    setShowExchangeTraining(false);
  };

  const onKeyDownInDiscussions = (e) => {
    if (e?.key === 'Enter') {
      onClickDiscussions();
    }
  };

  const onClickTraining = () => {
    setShowExchangeTraining(!showExchangeTraining);
    setShowTopDiscussions(false);
  };

  const onKeyDownInTraining = (e) => {
    if (e?.key === 'Enter') {
      onClickTraining();
    }
  };

  return (
    <Popover placement="left" isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
      <CustomPopoverTrigger isOpen={isOpen} tabIndex="-1">
        <IconButton
          aria-label="Open app launcher card"
          sx={{
            height: '32px',
            cursor: 'pointer',
            backgroundColor: isOpen ? '#CED8DD' : iconClosedcolor,
            borderRadius: '0.25rem',
          }}
          icon={(
            <MoreVertIcon
              onKeyDown={(e) => onKeyDownInTrigger(e)}
            />
          )}
        />
      </CustomPopoverTrigger>
      <PopoverContent
        style={{
          ...theme.styles.global.container,
          ...otherAppStyles,
          width: '300px',
          alignItems: 'left',
          textAlign: 'left',
        }}
        p="4px"
      >
        <PopoverBody style={{ display: 'flex' }}>
          <Flex flexDirection="column" width="260px">
            <Text
              style={{
                ...theme.styles.global.h4,
                display: 'inline-block',
                fontWeight: '600',
                fontSize: '16px',
              }}
              color="gray.800"
            >
              {app_title}
            </Text>
            <Text
              style={{
                ...theme.styles.global.body2,
                color: 'gray.600',
              }}
            >
              {description}
            </Text>
            <NavLink
              target="_blank"
              to={product_catalog}
              style={{
                ...theme.styles.global.body2,
                color: theme.colors.blue[600],
                marginTop: '4px',
              }}
            >
              Open in Product Catalog
            </NavLink>

            {/* External Links and Help icon */}
            <Box
              mt="7px"
              display="flex"
              flexDir="column"
              borderBottom={guidelines_accepted && '1px solid #CED8DD'}
              pb="8px"
            >
              {marketing_website?.trim()?.length > 2 && (
                <ExternalLink
                  link_title="App Website"
                  link_url={marketing_website}
                  iconComponent={<OpenInNewIcon fontSize="small" />}
                />
              )}

              {documentation && (
                <ExternalLink
                  link_title="Documentation"
                  link_url={documentation}
                  iconComponent={<OpenInNewIcon fontSize="small" />}
                />
              )}
              {my_apps && (
                <NavLink
                  to={`/help#${stringToID(app_title)}`}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: '0.5rem',
                  }}
                >
                  <HelpIcon fontSize="small" />
                  <Text
                    as="span"
                    ml="9px"
                    style={{
                      ...theme.styles.global.body2,
                      width: 'fit-content',
                    }}
                  >
                    Help
                  </Text>
                </NavLink>
              )}
            </Box>

            {/* Top Discussions */}
            {guidelines_accepted && (
              <Box
                mt="8px"
                mb="8px"
                style={{
                  ...theme.styles.global.h4,
                  fontSize: '12px',
                  fontWeight: 600,
                }}
              >
                <Flex
                  color="blue.600"
                  alignItems="center"
                  cursor="pointer"
                  onClick={() => onClickDiscussions()}
                  onKeyDown={(e) => onKeyDownInDiscussions(e)}
                  role="button"
                  tabIndex="0"
                >
                  <GroupsIcon fontSize="small" />
                  <Text
                    ml="9px"
                    color="blue.600"
                    style={{
                      ...theme.styles.global.h4,
                      fontSize: '12px',
                      fontWeight: 600,
                    }}
                  >
                    Top Discussions
                  </Text>
                </Flex>
                {showTopDiscussions && getTopDiscussionsData()}
              </Box>
            )}

            {/* Exchange Training */}
            {guidelines_accepted && (
              <Box
                pt="8px"
                borderTop="1px solid #CED8DD"
                style={{
                  ...theme.styles.global.h4,
                  fontSize: '12px',
                  fontWeight: 600,
                }}
              >
                {isTrainingAvailable ? (
                  <>
                    <Flex
                      color="blue.600"
                      alignItems="center"
                      cursor="pointer"
                      onClick={() => onClickTraining()}
                      onKeyDown={(e) => onKeyDownInTraining(e)}
                      role="button"
                      tabIndex="0"
                    >
                      <CollectionsBookmarkIcon fontSize="small" />
                      <Text
                        ml="9px"
                        color="blue.600"
                        style={{
                          ...theme.styles.global.h4,
                          fontSize: '12px',
                          fontWeight: 600,
                        }}
                      >
                        Exchange Trainings
                      </Text>
                    </Flex>
                    {showExchangeTraining && getCoursesData()}
                  </>
                ) : (
                  <span style={{ fontWeight: 400 }}>
                    {isFetchingCoursesData
                      ? 'Loading...'
                      : 'Training for this app is not yet available.'}
                  </span>
                )}
              </Box>
            )}
          </Flex>
          {my_apps && (
            <Box mt="0.5rem" width="40px">
              <TogglePinIcon
                is_pinned={is_pinned}
                create_pin_body_obj={createPinBodyObj}
                pin_id={pin_id}
                customStyle={{ padding: '0 2px', minWidth: '1.5rem' }}
                object_id={_id}
                is_app
              />
            </Box>
          )}
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default AppLauncherCard;
