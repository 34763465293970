/* eslint-disable no-unused-vars */
import {
  Box,
  Flex,
  useTheme,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Text,
  Select,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { NavLink, useLocation, useParams } from 'react-router-dom';
import Report from '../components/ModerationReports/Report';
import AllReports from '../components/ModerationReports/AllReports';
import { useFetchAllModerationReportsQuery } from '../services/apis/postsAPI';
import CustomBadge from '../components/ElementaryComponents/CustomBadge';
import SectionFooter from '../components/NavMenu/SectionFooter';

const reportsTab = ['all', 'group', 'app', 'course'];

const Reports = () => {
  const theme = useTheme();
  const location = useLocation();
  const { report_id } = useParams();
  const [reportFilter, setReportFilter] = useState('all');
  const isAllReportsPath = location.pathname === '/reports';
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedShowNumber, setSelectedShowNumber] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortOrder, setSortOrder] = useState('desc');

  const moderationReportsQueryObj = {
    page_number: currentPage,
    page_size: selectedShowNumber,
    sort_by: 'created_at',
    sort_order: sortOrder,
  };
  if (reportFilter !== 'all') {
    moderationReportsQueryObj.report_status = reportFilter;
  }

  if (selectedTab !== 0) {
    moderationReportsQueryObj.report_from = reportsTab[selectedTab];
  }

  const {
    data: moderationReportsData,
    isFetching: isModerationReportsFetching,
    isError: isModerationReportsError,
    error: moderationReportsError,
    refetch: refetchModerationReports,
  } = useFetchAllModerationReportsQuery(moderationReportsQueryObj);

  useEffect(() => {
    refetchModerationReports();
  }, [reportFilter, selectedTab, sortOrder, refetchModerationReports]);

  const report_pending = moderationReportsData?.meta?.report_pending;

  const handleTabChange = (index) => {
    setSelectedTab(index);
    setCurrentPage(1);
  };

  const handleReportFilterChange = (e) => {
    setReportFilter(e.target.value.trim());
    setCurrentPage(1);
  };
  return (
    <Box>
      <Flex justifyContent="space-between" alignItems="center" py={4}>
        <NavLink
          to="/reports"
          style={{
            ...theme.styles.global.h1,
            color: `${theme.colors.blue[800]}`,
            display: 'inline-block',
          }}
        >
          {isAllReportsPath ? 'Moderation' : 'Moderation Requests'}
        </NavLink>
      </Flex>
      {report_id ? (
        <Report />
      ) : (
        <Tabs onChange={handleTabChange} index={selectedTab}>
          <Flex>
            <TabList>
              <Tab
                _selected={{
                  fontWeight: 'bold',
                  color: 'blue.600',
                  borderBottom: '2px solid',
                }}
                style={{ width: '70px' }}
                position="relative"
              >
                All
                <CustomBadge itemCount={report_pending?.all} />
              </Tab>
              <Tab
                _selected={{
                  fontWeight: 'bold',
                  color: 'blue.600',
                  borderBottom: '2px solid',
                }}
                style={{ width: '100px' }}
                position="relative"
              >
                Groups
                <CustomBadge itemCount={report_pending?.group} />
              </Tab>
              <Tab
                _selected={{
                  fontWeight: 'bold',
                  color: 'blue.600',
                  borderBottom: '2px solid',
                }}
                style={{ width: '130px' }}
                position="relative"
              >
                App Groups
                <CustomBadge itemCount={report_pending?.app} />
              </Tab>
              <Tab
                _selected={{
                  fontWeight: 'bold',
                  color: 'blue.600',
                  borderBottom: '2px solid',
                }}
                style={{ width: '165px' }}
                position="relative"
              >
                Training Groups
                <CustomBadge itemCount={report_pending?.course} />
              </Tab>
            </TabList>
            <Flex align="center" sx={{ borderBottom: '2px solid #DFE7EB', justifyContent: 'end' }} flexGrow={1}>
              <Text mr={2} fontWeight="700">
                Filter requests:
              </Text>
              <Select
                aria-label="filter based on action status"
                width="max-content"
                cursor="pointer"
                pr="0"
                size="sm"
                mr="0.25rem"
                color="gray.600"
                value={reportFilter}
                onChange={handleReportFilterChange}
              >
                <option value="all">All</option>
                <option value="pending">Require Action</option>
                <option value="inprogress">Action Taken</option>
              </Select>
            </Flex>
          </Flex>
          <Flex
            justifyContent="space-between"
            alignItems="center"
            mt={4}
            mb={2}
          >
            <Text style={{ ...theme.styles.global.h4 }} color="blue.600">
              Request
            </Text>
            <Select
              aria-label="sort based on time"
              width="max-content"
              cursor="pointer"
              sx={{ border: 'none' }}
              value={sortOrder}
              pr="0"
              size="sm"
              mr="0.25rem"
              color="gray.600"
              onChange={(e) => setSortOrder(e.target.value)}
            >
              <option value="desc">Newest</option>
              <option value="asc">Oldest</option>
            </Select>
          </Flex>
          <TabPanels style={{ ...theme.styles.global.container }}>
            {
                reportsTab.map((reportTab) => {
                  return (
                    <TabPanel key={reportTab}>
                      <AllReports
                        tabSection={reportTab}
                        moderationReportsData={moderationReportsData}
                        isModerationReportsFetching={isModerationReportsFetching}
                        isModerationReportsError={isModerationReportsError}
                        moderationReportsError={moderationReportsError}
                      />
                    </TabPanel>
                  );
                })
              }
          </TabPanels>
          <SectionFooter
            selectedShowNumber={selectedShowNumber}
            setSelectedShowNumber={setSelectedShowNumber}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalCount={moderationReportsData?.meta?.total_pages}
          />
        </Tabs>
      )}
    </Box>
  );
};
export default Reports;
