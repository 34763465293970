import React from 'react';
import {
  Button, Modal, ModalOverlay, ModalContent,
  ModalHeader, ModalFooter, ModalCloseButton,
  useDisclosure, useTheme, Box, IconButton,
  Text,
} from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDeletePostMutation } from '../../services/apis/postsAPI';
import { useDeleteGroupMutation } from '../../services/apis/groupsAPI';
import { useDeleteCommentByIdMutation } from '../../services/apis/commentsAPI';
import { useDeleteCourseMutation } from '../../services/apis/coursesAPI';
import { useDeleteEventMutation } from '../../services/apis/eventsAPI';
import { useDeleteAnalyticsReportMutation } from '../../services/apis/reportsAPI';
import { DeleteIcon } from '../../icons';

const DeletePostModal = ({
  itemId, deleteType, variant = 'solid', customBtnStyle = {}, isIcon, size = 'sm', trainingNotDeleted = false,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { group_id } = useParams();
  const [deletePost, { isLoading: isDeletingPost }] = useDeletePostMutation();
  const [deleteGroup, { isLoading: isDeletingGroup }] = useDeleteGroupMutation();
  const [deleteComment, { isLoading: isDeletingComment }] = useDeleteCommentByIdMutation();
  const [deleteCourse, { isLoading: isDeletingCourse }] = useDeleteCourseMutation();
  const [deleteEvent, { isLoading: isDeletingEvent }] = useDeleteEventMutation();
  const [deleteReport, { isLoading: isDeletingReport }] = useDeleteAnalyticsReportMutation();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleDeleteGroup = async () => {
    try {
      await deleteGroup(itemId);
      navigate('/forum');
    } catch (error) {
      console.log(error);
    }
  };
  const handleDeletePost = async () => {
    try {
      await deletePost(itemId);
      navigate(`/forum/${group_id}`);
    } catch (error) {
      console.log(error);
    }
  };
  const handleDeleteComment = async () => {
    try {
      await deleteComment(itemId);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteCourse = async () => {
    try {
      await deleteCourse(itemId);
      navigate('/training');
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteEvent = async () => {
    try {
      await deleteEvent(itemId);
      navigate('/events');
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteReport = async () => {
    try {
      await deleteReport(itemId);
      navigate('/analytics');
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = () => {
    if (deleteType === 'post') handleDeletePost();
    if (deleteType === 'group') handleDeleteGroup();
    if (deleteType === 'comment') handleDeleteComment();
    if (deleteType === 'course') handleDeleteCourse();
    if (deleteType === 'event') handleDeleteEvent();
    if (deleteType === 'report') handleDeleteReport();
  };

  return (
    <Box>
      {
        isIcon
          ? (
            <IconButton
              aria-label="Delete this item"
              sx={{ marginLeft: '4px', marginTop: '4px' }}
              onClick={onOpen}
              icon={<DeleteIcon boxSize="1.5em" cursor="pointer" color="blue.500" />}
            />
          )
          : (
            <Button
              onClick={onOpen}
              colorScheme="blue"
              bg={variant === 'solid' ? 'blue.500' : 'white'}
              variant={variant}
              style={{ ...theme.styles.global.button, fontWeight: '600', ...customBtnStyle }}
              size={size}
            >
              Delete
            </Button>
          )
      }

      <Modal isOpen={isOpen} onClose={onClose} size="xl" isCentered>
        <ModalOverlay />
        <ModalContent maxWidth="757px" minHeight="150px" borderTop="8px solid" borderColor="blue.600" style={{ ...theme.styles.components.modalStyle }}>
          <ModalHeader>
            <Text style={{ ...theme.styles.global.h3 }}>
              Are you sure you want to delete this
              {' '}
              {deleteType}
              ?
            </Text>
            <Text mt={4}>
              {trainingNotDeleted ? 'This group is associated with a training, and may have content related to that training.' : ''}
            </Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalFooter alignSelf="flex-start" mt="1rem" width="100%">
            <Button
              mr={3}
              onClick={onClose}
              variant="outline"
              border="1px solid #CED8DD"
              size="sm"
              _hover={{ opacity: 0.9 }}
              color="blue.600"
            >
              Cancel
            </Button>

            <Button
              colorScheme="blue"
              bg="blue.500"
              onClick={() => handleDelete()}
              size="sm"
              disabled={isDeletingPost || isDeletingGroup
                || isDeletingComment || isDeletingCourse || isDeletingEvent || isDeletingReport}
            >
              Delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default DeletePostModal;
