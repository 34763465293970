import apiService from '../apiService';

export const eventsAPI = apiService.injectEndpoints({
  endpoints: (builder) => ({
    fetchAllEvents: builder.query({
      query: ({
        page_number, page_size, sort_by, sort_order, pin, query, last_created, start_date, end_date,
      }) => ({
        url: 'events/v1',
        params: {
          page_number,
          page_size,
          sort_by,
          sort_order,
          pin,
          query,
          last_created,
          start_date,
          end_date,
        },
      }),
      providesTags: ['events'],
    }),
    fetchEventById: builder.query({
      query: (event_id) => ({
        url: `events/v1/${event_id}`,
      }),
      providesTags: ['event'],
      refetchOnMountOrArgChange: true,
    }),
    updateEventById: builder.mutation({
      query: ({ event_id, eventData }) => ({
        url: `events/v1/${event_id}`,
        method: 'PUT',
        body: eventData,
      }),
      invalidatesTags: ['event', 'events'],
    }),
    deleteEvent: builder.mutation({
      query: (event_id) => ({
        url: `events/v1/${event_id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['events'],
    }),
    createEvent: builder.mutation({
      query: (newEventData) => ({
        url: 'events/v1',
        method: 'POST',
        body: newEventData,
      }),
      invalidatesTags: ['events'],
    }),
    reactToEvent: builder.mutation({
      query: ({ event_id, reaction_type }) => ({
        url: `events/v1/${event_id}/reactions?reaction_type=${reaction_type}`,
        method: 'POST',
      }),
      async onQueryStarted({ event_id, user_id, reaction_type }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          apiService.util.updateQueryData('fetchEventById', event_id, (draft) => {
            const reaction = draft.data.reactions[reaction_type];
            if (reaction.reacted) {
              const index = reaction.users.indexOf(user_id);
              if (index !== -1) {
                reaction.users.splice(index, 1);
              }
              delete reaction.reacted;
            } else {
              reaction.users.push(user_id);
              reaction.reacted = true;
            }
          }),
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult.undo();
        }
      },
      invalidatesTags: ['events'],
    }),
  }),
});

export const {
  useFetchAllEventsQuery,
  useFetchEventByIdQuery,
  useUpdateEventByIdMutation,
  useDeleteEventMutation,
  useCreateEventMutation,
  useReactToEventMutation,
} = eventsAPI;
