/* eslint-disable no-unused-vars */
import React, { useContext } from 'react';
import { useParams, NavLink } from 'react-router-dom';
import {
  VStack,
  HStack,
  Flex,
  useTheme,
  Text,
  Link,
  Heading,
} from '@chakra-ui/react';
import parse from 'html-react-parser';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { TEEAuthDataContext } from '@texas-education-exchange/edx-portal-shared';
import { ThumbUpOffAltIcon, ThumbUpAltIcon } from '../../icons/index';
import DisplayTags from '../ElementaryComponents/DisplayTags';
import TogglePinIcon from '../ElementaryComponents/TogglePinIcon';
import UserAvatar from '../ElementaryComponents/UserAvatar';
import StatusAlert from '../ElementaryComponents/StatusAlert';
import ProfileCardPopOver from '../PopOver/ProfileCardPopOver';
import {
  useFetchEventByIdQuery,
  useReactToEventMutation,
} from '../../services/apis/eventsAPI';
import { formatLink, joinTruthyStrings } from '../../utils/helpers';
import CreateEventModal from '../Modals/CreateEventModal';
import DeleteModal from '../Modals/DeleteModal';
import ReactionIconComponent from '../ElementaryComponents/ReactionIconComponent';

const Event = ({ guidelines_accepted }) => {
  const theme = useTheme();
  const { event_id } = useParams();
  const { auth } = useContext(TEEAuthDataContext);

  const loggedInUser = useSelector((state) => state.auth.loggedInUser);
  const loggedInUserId = loggedInUser?.profile.sub;

  // const canEditandDelete = (auth?.user?.profile?.role ?? []).some((r) => {
  //   return ['Community.ExchangeAdmin'].includes(r);
  // });

  const {
    data: eventData,
    isFetching: isEventFetching,
    isError: isEventError,
    error: eventError,
    isSuccess: isEventFetched,
    refetch: refetchEventData,
  } = useFetchEventByIdQuery(event_id);

  const [reactToEvent] = useReactToEventMutation();

  const canEdit = eventData?.permissions?.event?.can_edit;
  const canDelete = eventData?.permissions?.event?.can_delete;

  const handleEventReaction = () => {
    reactToEvent({
      event_id,
      user_id: loggedInUserId,
      reaction_type: 'like',
    }).unwrap();
  };

  const handlePinChange = () => {
    refetchEventData();
  };
  const eventComponent = () => {
    if (isEventFetching) return <StatusAlert status="loading" alert_title="Fetching Event Data" />;
    if (isEventError) return <StatusAlert status="error" error={eventError} />;
    const {
      title,
      description,
      tags = [],
      reactions,
      start_date,
      end_date,
      pin,
      pin_id,
      event_host,
      contact_person,
      _id,
      type = '',
      author_id,
      registration_link,
      location = {},
      author_body = {},
    } = eventData.data;

    const {
      address = '', city = '', state = '', zip_code = '',
    } = location;

    const formattedStartDate = moment(start_date).format('MMM Do, YYYY, h:mmA');
    const formattedEndDate = moment(end_date).format('MMM Do, YYYY, h:mmA');

    const alreadyLiked = reactions?.like?.users.includes(loggedInUserId);

    let authorName = joinTruthyStrings({
      strings: [author_body.first_name, author_body.last_name],
      connectingString: ' ',
    });
    if (!authorName) authorName = author_id;
    const isAuthor = loggedInUserId === author_id;

    // Create Pin Body
    const createPinBodyObj = {
      child_object_id: _id,
      child_object_type: 'event',
    };

    return (
      <>
        <VStack justifyContent="space-between" py={4} mb={8} alignItems="left">
          <NavLink
            to="/events"
            style={{
              ...theme.styles.global.h1,
              color: `${theme.colors.blue[800]}`,
              display: 'inline-block',
              width: 'max-content',
            }}
          >
            Events
          </NavLink>
          <Flex alignItems="center" justify="space-between" width="100%">
            <Text
              style={{ ...theme.styles.global.h3, marginTop: 8 }}
              color="blue.800"
            >
              {parse(title)}
            </Text>
            <Flex
              style={{
                marginLeft: 'auto',
                justifyContent: 'space-around',
                width: '150px',
                alignItems: 'center',
              }}
            >
              <Flex alignItems="center">
                <Text w="30px" textAlign="right">
                  {reactions?.like?.users?.length}
                </Text>
                <ReactionIconComponent
                  alreadyLiked={alreadyLiked}
                  handleClick={handleEventReaction}
                />
              </Flex>
              <TogglePinIcon
                is_pinned={pin}
                create_pin_body_obj={createPinBodyObj}
                pin_id={pin_id}
                updatePinState={handlePinChange}
              />
            </Flex>
          </Flex>
          <DisplayTags tags={tags} />
        </VStack>

        {/* Event Section */}
        <VStack
          style={{
            ...theme.styles.global.container,
            width: '100%',
            alignItems: 'flex-start',
          }}
          p="1rem"
          bg="white"
          mb="3rem"
        >
          <Heading as="h3" style={{ ...theme.styles.global.h3 }}>
            Description
          </Heading>
          <HStack alignItems="flex-start" justify="space-between" width="100%">
            <Flex flexDirection="column" gap="1rem">
              <Text
                style={{
                  ...theme.styles.global.body,
                  fontWeight: 400,
                  color: '#000000',
                }}
                pr="2rem"
                whiteSpace="pre-wrap"
              >
                {parse(description)}
              </Text>
              <div>
                <Heading
                  as="h4"
                  style={{ ...theme.styles.global.h4 }}
                  mb="0.25rem"
                >
                  Date & Time
                </Heading>
                <Text style={{ ...theme.styles.global.body }}>
                  {formattedStartDate}
                  {' to '}
                  {formattedEndDate}
                </Text>
              </div>
              <div>
                <Heading
                  mt="4"
                  as="h4"
                  style={{ ...theme.styles.global.h4 }}
                  mb="0.25rem"
                >
                  Location -
                  {' '}
                  {type}
                </Heading>
                {['On site', 'Hybrid'].includes(type) && (
                <>
                  <Text style={{ ...theme.styles.global.body }}>
                    {`${parse(address)}, `}
                  </Text>
                  <Text style={{ ...theme.styles.global.body }}>
                    {joinTruthyStrings({
                      strings: [
                        joinTruthyStrings({
                          strings: [parse(city), parse(state)],
                        }),
                        parse(zip_code),
                      ],
                      connectingString: ' ',
                    })}
                  </Text>
                </>
                )}
              </div>
              {registration_link && (
                <Flex>
                  <Text style={{ ...theme.styles.global.body }}>
                    Register:&nbsp;
                  </Text>
                  <Link
                    href={formatLink(registration_link)}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      ...theme.styles.global.body,
                      color: `${theme.colors.blue[500]}`,
                    }}
                  >
                    {registration_link}
                  </Link>
                </Flex>
              )}
            </Flex>
            <Flex
              flexDirection="column"
              gap="1rem"
              minWidth="250px"
              p="1rem"
              pt={0}
              bg="white"
              mb="3rem"
            >
              <h6>CREATED BY</h6>
              <Flex alignItems="center" marginBottom="3rem" gap="0.5rem">
                <ProfileCardPopOver
                  memberProfile={author_body}
                  userAvatar={
                    <UserAvatar name={authorName} user_profile_pic="" />
                  }
                />
                <Text style={{ ...theme.styles.global.body }}>
                  {authorName}
                </Text>
              </Flex>
              <h6>CONTACT PERSON</h6>
              <Flex alignItems="center" marginBottom="3rem">
                <Text style={{ ...theme.styles.global.body }}>
                  {parse(contact_person)}
                </Text>
              </Flex>
              {event_host && (
                <>
                  <h6>HOST/SPONSOR</h6>
                  <Text
                    style={{ ...theme.styles.global.body, fontWeight: '400' }}
                  >
                    {parse(event_host)}
                  </Text>
                </>
              )}
              <Flex justifyContent="start">
                {canEdit && guidelines_accepted && (
                  <CreateEventModal
                    buttonLabel="Edit"
                    isEditing
                    eventData={eventData?.data}
                    title="My Modal"
                    size="sm"
                    variant="outline"
                    customBtnStyle={{ width: '108px', marginRight: '0.5rem' }}
                  />
                )}
                {canDelete && guidelines_accepted && (
                  <DeleteModal
                    itemId={_id}
                    deleteType="event"
                    customBtnStyle={{ width: '108px', marginRight: '0.5rem' }}
                    size="sm"
                  />
                )}
              </Flex>
            </Flex>
          </HStack>
        </VStack>
      </>
    );
  };
  return eventComponent();
};

export default Event;
