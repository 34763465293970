import React, { useEffect } from 'react';
import {
  Box, useTheme, Heading, Flex, Button,
} from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';
import { useFetchAllAppsQuery } from '../services/apis/appsAPI';
import StatusAlert from '../components/ElementaryComponents/StatusAlert';
import HelpCard from '../components/Cards/HelpCard';
import { stringToID } from '../utils/helpers';
import { useGetUserQuery } from '../services/apis/usersAPI';

const isTitleHashMatch = (title, hash) => {
  const normalizeHash = hash.toLowerCase().slice(1);
  const titleToHash = stringToID(title);
  return normalizeHash === titleToHash;
};

const removeHashCharacter = (str) => str.slice(1);

const Help = ({ guidelines_accepted }) => {
  const theme = useTheme();
  const location = useLocation();
  const getApps = useFetchAllAppsQuery();
  const { data: userData } = useGetUserQuery();
  const canSubmitToJira = userData?.access_sidebar?.help?.submit_help_request;
  const canCheckTheKnowledgeBase = userData?.access_sidebar?.help?.check_knowledge_base;

  const {
    data: appData, isFetching, isError, error,
  } = getApps;
  const appDataInner = appData?.data[0];
  const combinedApps = [
    ...(appDataInner?.pinned_apps ?? []),
    ...(appDataInner?.your_other_apps) ?? [],
  ]
    .sort((a, b) => {
      if (a.title.toLowerCase() === 'community') return -1;
      if (b.title.toLowerCase() === 'community') return 1;
      return 0;
    });

  const { hash } = location;

  useEffect(() => {
    const element = document.getElementById(removeHashCharacter(hash));

    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      });
    }
  }, [hash]);

  const onClickHelpRequest = () => {
    window.open(
      'https://txedexchange.atlassian.net/servicedesk/customer/portals',
      '_blank',
      'noreferrer',
    );
  };

  const onClickKnowledgeBase = () => {
    window.open(
      'https://txedexchange.atlassian.net/wiki/spaces/CS/overview',
      '_blank',
      'noreferrer',
    );
  };

  return (
    <Box>
      {isFetching ? (
        <StatusAlert status="loading" alert_title="Loading..." />
      ) : isError ? (
        <StatusAlert status="error" error={error} />
      ) : (
        <>
          <Flex mb="1rem">
            <Heading
              as="h1"
              mb="1rem"
              style={{
                ...theme.styles.global.h1,
                color: 'blue.800',
              }}
            >
              Help With The Exchange
            </Heading>
            {canSubmitToJira && guidelines_accepted && (
              <Button
                sx={{
                  marginLeft: 'auto',
                }}
                variant="primary"
                onClick={onClickHelpRequest}
              >
                Submit a Help Request
              </Button>
            )}
          </Flex>
          <Flex flexDirection="column" marginBottom="4rem">
            {combinedApps.map((s) => (
              <HelpCard
                isOpen={isTitleHashMatch(s.title, hash)}
                {...s}
                key={s._id}
                guidelines_accepted={guidelines_accepted}
              />
            ))}
          </Flex>
          {canSubmitToJira && (
          <Flex justify="center" marginBottom="4rem">
            {canCheckTheKnowledgeBase && (
              <Button
                variant="unstyled"
                sx={{
                  ...theme.styles.global.button,
                  ...theme.styles.global.h2,
                  color: 'blue.500',
                }}
                onClick={onClickKnowledgeBase}
              >
                Check the Knowledge Base
              </Button>
            )}
          </Flex>
          )}
        </>
      )}
    </Box>
  );
};

export default Help;
