import {
  Flex, Text, Heading, useTheme,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { useFetchMyModerationReportsQuery } from '../services/apis/postsAPI';
import AllReports from '../components/ModerationReports/AllReports';
import GuidelinesModal from '../components/Modals/GuidelinesModal';
import SectionFooter from '../components/NavMenu/SectionFooter';
import { useGetUserQuery } from '../services/apis/usersAPI';
import StatusAlert from '../components/ElementaryComponents/StatusAlert';

const Infractions = () => {
  const theme = useTheme();
  const [selectedShowNumber, setSelectedShowNumber] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);

  const {
    data: userData,
    isFetching: isUserDataFetching,
  } = useGetUserQuery();
  const latest_guidelines_status = userData?.data?.latest_guidelines_status;
  const guidelines_accepted = latest_guidelines_status === 'accepted';

  const myReportsQueryObj = {
    page_number: currentPage,
    page_size: selectedShowNumber,
    sort_by: 'created_at',
    sort_order: 'asc',
  };
  const {
    data: myReportsData,
    isFetching: isMyReportsFetching,
    isError: isMyReportsError,
    error: myReportsError,
  } = useFetchMyModerationReportsQuery(myReportsQueryObj);

  return (
    <Flex flexDirection="column" gap={4} mt={2}>
      <Heading
        style={{ ...theme.styles.global.h3 }}
        color="blue.800"
      >
        Infractions
      </Heading>
      <Text
        style={{ ...theme.styles.global.h5 }}
        color="blue.800"
      >
        This is where you can see actions taken by moderators that affect you.
      </Text>
      <Text
        color="blue.800"
        mb="1rem"
      >
        You will receive an infraction when you’ve made a post or reply the moderators
        feel goes against our Community Guidelines, or you’ve otherwise behaved in ways
        that require action to be taken. Each action will appear here, and you will have
        an opportunity to appeal them one time each. If you have too many infractions,
        you may lose your ability to interact with other members of the Community.
      </Text>
      {isUserDataFetching
        ? <StatusAlert status="loading" alert_title="Fetching Details" />
        : (
          <GuidelinesModal
            custom_text="You can review the Community Guidelines here."
            guidelines_accepted={guidelines_accepted}
            custom_btn_style={{
              color: `${theme.colors.blue[500]}`,
              fontWeight: 700,
              marginLeft: 0,
              fontSize: '16px',
            }}
            btn_type="text"
          />
        )}
      {/* Infractions Data */}
      <Flex
        style={{
          ...theme.styles.global.container,
        }}
        padding={4}
        flexDir="column"
      >
        {/* {getInfractionsData()} */}
        <AllReports
          moderationReportsData={myReportsData}
          isModerationReportsFetching={isMyReportsFetching}
          isModerationReportsError={isMyReportsError}
          moderationReportsError={myReportsError}
          isInfractions
          customNoDataMessage="You don’t have any infractions, thank you for following the Community Guidelines."
        />
        {myReportsData?.meta?.total_pages ? (
          <>
            <Text borderTop={`1px solid ${theme.colors.gray[300]}`} pt="1rem" />
            <SectionFooter
              selectedShowNumber={selectedShowNumber}
              setSelectedShowNumber={setSelectedShowNumber}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              totalCount={myReportsData?.meta?.total_pages}
            />
          </>
        ) : ''}
      </Flex>
    </Flex>
  );
};

export default Infractions;
