import React from 'react';
import { Box, useTheme, Text } from '@chakra-ui/react';
import GuidelinesModal from '../Modals/GuidelinesModal';

const GuidelinesNotAccepted = ({
  display_message = ['You Must Agree to the User Guidelines to See This Page',
    { btn_text: 'View Community Guidelines' }], customStyle = {},
  btn_type,
}) => {
  const theme = useTheme();
  const is_default_message = display_message[0] === 'You Must Agree to the User Guidelines to See This Page';
  const conatiner_style = is_default_message ? { textAlign: 'center', marginTop: '12rem' } : theme.styles.global.container;
  return (
    <Box
      tabIndex="0"
      sx={{
        ...conatiner_style,
        fontSize: '14px',
        lineHeight: '20px',
        fontWeight: '400',
        padding: '1rem',
        ...customStyle,
      }}
    >
      {
        display_message.map((part) => {
          if (typeof part === 'string') {
            if (is_default_message) {
              return (
                <Text style={{
                  display: 'block',
                  marginBottom: '1rem',
                  fontWeight: '700',
                  fontSize: '18px',
                }}
                >
                  {part.trim()}
                </Text>
              );
            }
            return `${part.trim()} `;
          }
          return (
            <GuidelinesModal
              btn_type={btn_type}
              custom_text={part?.btn_text}
              custom_btn_style={{
                color: `${theme.colors.blue[500]}`,
                fontWeight: 700,
                marginLeft: 0,
                fontSize: '14px',
              }}
            />
          );
        })
      }
    </Box>
  );
};

export default GuidelinesNotAccepted;
